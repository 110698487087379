﻿@import '../framework/imports.less';
// <hr> seperators

.hr,
.separator {
    .separator-mixin();

    &.separator-margin-lg {
        margin: @margin-large 0 @margin-base;
    }
}

.separator-inner,
.separator-inner-1,
.separator-inner-2,
.separator-inner-3,
.separator-inner-4 {
    background-position: 50% 0;
    background-repeat: no-repeat;
}

.separator-1,
.separator-2,
.separator-3,
.separator-4 {
    .separator-mixin();
}

.separator-after-1,
.separator-after-2,
.separator-after-3,
.separator-after-4 {
    position: relative;
    padding-bottom: 5px;
    margin-bottom: @separator-height + @margin-base;

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        .separator;
    }
}

@media (max-width: @screen-xs-max) {
    .separator,
    .separator-1,
    .separator-2,
    .separator-3,
    .separator-4,
    .separator-inner,
    .separator-inner-1,
    .separator-inner-2,
    .separator-inner-3,
    .separator-inner-4 {
        background-image: url(//static.evo.com/content/www/components/separators/separator-small.png);
    }

    .separator-after-1:after,
    .separator-after-2:after,
    .separator-after-3:after,
    .separator-after-4:after {
        .separator;
    }
}

@media (min-width: @screen-sm-min) {
    .separator-1,
    .separator-inner-1 {
        background-image: url(//static.evo.com/content/www/components/separators/separator-1.png);
    }

    .separator-2,
    .separator-inner-2 {
        background-image: url(//static.evo.com/content/www/components/separators/separator-2.png);
    }

    .separator-3,
    .separator-inner-3 {
        background-image: url(//static.evo.com/content/www/components/separators/separator-3.png);
    }

    .separator-4,
    .separator-inner-4 {
        background-image: url(//static.evo.com/content/www/components/separators/separator-4.png);
    }

    .separator-after-1:after {
        .separator-1;
    }

    .separator-after-2:after {
        .separator-2;
    }

    .separator-after-3:after {
        .separator-3;
    }

    .separator-after-4:after {
        .separator-4;
    }
}

.separator-shadow {
    position: relative;

    & > div,
    & > p {
        background: #fff;
        position: relative;
        z-index: @separator-overlay-z-index;
    }

    &:after {
        z-index: 1;
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        left: 3%;
        right: 3%;
        border-radius: ~"35% / 20px";
        box-shadow: 0 0 10px rgba(0,0,0,.7);

        @media (min-width: @screen-sm-min) {
            left: 10%;
            right: 10%;
            border-radius: ~"50% / 20px";
            box-shadow: 0 0 18px rgba(0,0,0,.7);
        }
    }

    &-top, &-bottom {
        &:after {
            height: 50%;
        }
    }

    &-bottom {
        &:after {
            top: auto;
            bottom: 0;
        }
    }
}

@media print {
    hr,
    .separator,
    .separator-1,
    .separator-2,
    .separator-3,
    .separator-4 {
        border-bottom: 1px solid #ccc;
        margin: @margin-base 0;
        padding: 0;
        background: none;
    }
}