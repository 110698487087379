﻿@import '../framework/imports.less';
// global reviews styles

div.container#pr-form-container {
    padding-bottom: 10px;
}

.p-w-r {
    font-family: @font-family-loaded;
}

.p-w-r .pr-snippet {
    min-height: 12px;
}

.p-w-r .pr-category-snippet {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.pr-category-snippet__rating {
    display: block;
}

.p-w-r .pr-snippet-rating-decimal {
    display: none !important;
}

.p-w-r .pr-snippet .pr-snippet-stars {
    display: block;
}

.p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
    display: flex;
}

// compare table reviews

.compare-product-column .p-w-r .pr-category-snippet {
    @media (max-width: @screen-lg + 1) {
        flex-direction: column;
        align-items: center;
    }
}

// results page

@media (max-width: @navigation-breakpoint) {
    .results-product-thumbs .p-w-r .pr-category-snippet {
        flex-direction: column;
        align-items: center;

        .pr-category-snippet__total {
            padding-top: 5px;
            padding-left: 0px;
            padding-right: 2px;
        }
    }
}

// product thumb reviews

.product-thumb-details {
    .pr-no-reviews {
        display: none;
    }

    .p-w-r .pr-category-snippet {
        margin-top: 5px;
        margin-bottom: 5px;
        justify-content: center;
    }

    .pr-category-snippet__rating {
        display: block;
    }

    .p-w-r .pr-snippet .pr-snippet-stars {
        display: block;
    }

    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-rating-stars {
        display: flex;
    }

    .p-w-r .pr-snippet-stars .pr-snippet-stars-png {
        height: 12px;
        line-height: 12px;
        display: inline-block;
    }

    .pr-rating-stars {
        height: 12px;
        line-height: 12px;
    }

    .p-w-r .pr-snippet .pr-snippet-stars-png .pr-star-v4 {
        width: 12px;
        height: 12px;
    }

    .pr-snippet-rating-decimal {
        display: none;
    }

    .pr-category-snippet__total {
        font-size: 12px;
        line-height: 1em;
        color: @color-gray-dark;
        font-family: @font-family-loaded;
        padding-top: 0;
        padding-bottom: 0;
    }
}

/*@import '../libs/icomoon.less';

#evo-body {
    .review-component-CategorySnippet,
    .review-component-ReviewSnippet {
        display: inline;
        vertical-align: text-bottom;

        .p-w-r {
            display: inline;
            margin: 0;
            vertical-align: text-top;
        }
    }

    .review-component-CategorySnippet .pr-no-reviews {
        display: none;
    }

    .review-component-ReviewSnippet {
        .pr-snippet-read-and-write {
            margin: 0;
        }
    }

    .pr-star-v4 {
        background-image: none;
        color: #f99400;
        height: @font-size-small;
        font-family: 'icomoon';
        font-size: @font-size-small;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        line-height: 1;
        margin-right: 2px;
        overflow: hidden;
        padding: 0;
        speak: none;
        text-transform: none;
        vertical-align: baseline;
        width: @font-size-small;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .pr-star-v4-0-filled:before,
    .pr-star-v4-25-filled:before {
        content: @icon-r0;
    }

    .pr-star-v4-50-filled:before,
    .pr-star-v4-75-filled:before {
        content: @icon-r-5;
    }

    .pr-star-v4-100-filled:before {
        content: @icon-r1;
    }

    .pr-snippet-rating-decimal {
        display: none;
    }

    .pr-category-snippet__total {
        display: none;
        font-size: @font-size-small;

        @media (min-width:@screen-sm-min) {
            display: inline;
        }

        &:before {
            content: '('
        }

        &:after {
            content: ')'
        }
    }

    .pr-snippet-reco-to-friend{
        display: none;
    }

    .pr-category-snippet{
        white-space: nowrap;
    }
}

.pdp-header-utilities {
    .p-w-r {
        display: inline-block !important;
    }
}*/