@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/buttons.less';

.btn {
    border: 0;
    .button-size((@padding-base-vertical + 2); 25px; 14px; @line-height-base; @border-radius-base);
    line-height: 1;
    //max-width: 100%;
    text-decoration: none;
    text-transform: none;
    white-space: normal;
    border-radius: 0;
    font-weight: @font-weight-bold;

    &.disabled, &[disabled] {
        background: @color-gray-light;
        .opacity(1);
    }
}

.no-touch .btn {
    .transition(background .3s ease-in);
}

.btn-link,
.btn-link.btn-lg {
    font-weight: normal;
    text-transform: none;
    text-decoration: underline;
    font-size: @font-size-base;
}
// Button Sizes
// --------------------------------------------------

.btn-lg {
    font-size: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.btn-sm {
    .button-size((@padding-small-vertical - 1); @padding-small-horizontal; @font-size-small; @line-height-small; 0);
}

.btn-xs {
    .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; 0);
}
// This is really a part of theme.less
.btn-default {
    &:hover,
    &:focus {
        background-color: #000;
    }
}

.btn-info {
    background: transparent;
    border: 2px solid #000;
    color: #000;

    &:hover,
    &:active, &:focus {
        border: 2px solid #000;
        color: #000;
        background-color: rgba(0,0,0,.2);
    }
}

.negative .btn-info {
    border: 2px solid @color-white;
    color: @color-white;

    &:hover,
    &:active, &:focus {
        border: 2px solid @color-white;
        color: @color-white;
    }
}

.btn-primary:hover, .btn-primary:hover {
    background-color: darken(@color-green-dark, 5%);
    border-color: darken(@color-green-dark, 5%);
}

// ZR custom
.btn-blue {
    .button-variant(#fff;@color-blue-dark;@color-blue-dark);
}

.btn-dark-teal {
    .button-variant(#fff;@color-dark-teal;@color-dark-teal);

    &.disabled, &[disabled] {
        background: @color-gray-light;
        .opacity(1);
    }
}

.btn-tomato {
    .button-variant(#fff;@color-tomato-wcag;@color-tomato-wcag);

    &.disabled, &[disabled] {
        background: @color-gray-light;
        .opacity(1);
    }
}

.btn-white {
    .button-variant(#000;@color-white;@color-white);

    &.disabled, &[disabled] {
        background: @color-gray-light;
        .opacity(1);
    }
}

.btn-reversed {
    .button-variant(@color-dark-teal;#fff;#fff);

    &:hover,
    &:focus,
    &:active,
    &.active {
        color: #fff;
        background-color: @color-dark-teal;
        border-color: @color-dark-teal;
    }

    &.disabled, &[disabled] {
        background: @color-gray-light;
        .opacity(1);
    }
}

.btn-secondary {
    font-size: @font-size-base;
    color: @color-dark-teal;
    font-weight: normal;
    background: transparent;
    padding: 0;
    line-height: 1;

    &:after {
        content: '>>';
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        transition: all 0.3s ease;
    }

    &:hover, &:focus {
        color: @color-dark-teal;

        &:after {
            margin-left: 8px;
        }
    }
}

.btn-link-super {
    font-size: 16px;
    color: @color-dark-teal;
    background: transparent;
    padding: 0;
    line-height: 1;
    text-transform: uppercase;

    &:after {
        content: '>>';
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        transition: all 0.3s ease;
    }

    &:hover, &:focus {
        color: @color-dark-teal;

        &:after {
            margin-left: 8px;
        }
    }
}

.btn-xl {
    .button-size(15px;30px;18px;1em;0);

    @media (min-width: @screen-sm) {
        font-size: @font-size-large;
        padding: @margin-base;
    }
}

.btn-full {
    width: 100%;
}

.btn-wide {
    padding-left: 40px;
    padding-right: 40px;
}

.btn-remove {
    font-size: 18px;
    line-height: 1em;
}

.input-group {
    .btn {
        padding-left: @padding-base-horizontal;
        padding-right: @padding-base-horizontal;
        padding-top: inherit;
        padding-bottom: inherit;
        border-left: none;
    }

    label {
        display: table-cell;
        margin: 0;
        vertical-align: middle;
    }
}

.btn-load-more {
    margin-top: 15px;
    margin-bottom: 15px;

    @media (min-width: @screen-sm) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.btn-unformat {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
}