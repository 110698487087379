@import '../framework/imports.less';

//mixins

//mixin to generate the colors for the blog and all things

.generate-blog-colors(@slug; @color) {
    //date background color for different blog items
    .category-@{slug} .entry-date {
        background-color: fadeout(@color, 15%);
    }
    //date background color for the blog intros
    .body-@{slug} .entry-feature .entry-date {
        background-color: fadeout(@color, 15%);
    }
    //category link colors
    .category-@{slug} .entry-category-links a {
        color: @color;

        &:hover, &:active {
            color: darken(@color, 10%);
        }
    }
    //intro text border buttom
    .body-@{slug} .content-intro-header-text {
        border-bottom-color: @color;
    }
    // .content-hero-@{slug}:after {
    //   background:fadeout(@color, 40%);
    // }
}

//end mixins

//mixin to generate all the category specific colors for the blog posts.
.generate-blog-colors(cause, @color-blog-cause);
.generate-blog-colors(comps, @color-blog-comps);
.generate-blog-colors(events, @color-blog-events);
.generate-blog-colors(featured, @color-blog-featured);
.generate-blog-colors(new-arrivals, @color-blog-new-arrivals);
.generate-blog-colors(news, @color-blog-news);
.generate-blog-colors(parties, @color-blog-parties);
.generate-blog-colors(portland, @color-blog-portland);
.generate-blog-colors(seattle, @color-blog-seattle);
.generate-blog-colors(skate, @color-blog-skate);
.generate-blog-colors(ski, @color-blog-ski);
.generate-blog-colors(snowboards, @color-blog-snowboards);