@import 'variables-custom.less';
@import 'bootstrap-override/variables.less';
@import 'bootstrap-override/mixins.less';
/*
If you need to convert a mixin to a class name like
like .border-bottom(@color) to .border-bottom, do it
here.
*/
.bold,
.font-weight-bold,
.bold,
.font-weight-bold {
    font-weight: @font-weight-bold;
}

.font-weight-regular,
.font-weight-regular {
    font-weight: @font-weight-regular;
}

.font-weight-semibold,
.font-weight-semibold {
    font-weight: @font-weight-semibold;
}

.clear-list-formatting {
    .clear-list-formatting();
}
// Colors
.color-blue {
    color: @color-blue;
}

.bg-color-blue {
    background-color: @color-blue;
}

.color-green {
    color: @color-green;
}

.bg-color-green {
    background-color: @color-green;
}

.color-green-dark {
    color: @color-green-dark;
}

.bg-color-green-dark {
    background-color: @color-green-dark;
}

.color-green-darker {
    color: @color-green-darker;
}

.bg-color-green-darker {
    background-color: @color-green-darker;
}

.color-green-light {
    color: @color-green-light;
}

.bg-color-green-light {
    background-color: @color-green-light;
}

.color-green-super-light {
    color: @color-green-super-light;
}

.bg-color-green-super-light {
    background-color: @color-green-super-light;
}

.color-red {
    color: @color-red;
}

.bg-color-red {
    background-color: @color-red;
}

.color-dark-teal {
    color: @color-dark-teal;
}

.bg-color-dark-teal {
    background-color: @color-dark-teal;
}

.color-tomato {
    color: @color-tomato;
}

.bg-color-tomato {
    background-color: @color-tomato;
}

.color-tomato-wcag {
    color: @color-tomato-wcag;
}

.bg-color-tomato-wcag {
    background-color: @color-tomato-wcag;
}

.color-sunny {
    color: @color-sunny;
}

.bg-color-sunny {
    background-color: @color-sunny;
}

.color-sage {
    color: @color-sage;
}

.bg-color-sage {
    background-color: @color-sage;
}

.color-sage-wcag {
    color: @color-sage-wcag;
}

.bg-color-sage-wcag {
    background-color: @color-sage-wcag;
}

.color-sage-light {
    color: @color-sage-light;
}

.bg-color-sage-light {
    background-color: @color-sage-light;
}

.color-sky-blue {
    color: @color-sky-blue;
}

.bg-color-sky-blue {
    background-color: @color-sky-blue;
}

.color-sky-blue-wcag {
    color: @color-sky-blue-wcag;
}

.bg-color-sky-blue-wcag {
    background-color: @color-sky-blue-wcag;
}

.color-darker-teal {
    color: @color-darker-teal;
}

.bg-color-darker-teal {
    background-color: @color-darker-teal;
}

.color-light-tan {
    color: @color-light-tan;
}

.bg-color-light-tan {
    background-color: @color-light-tan;
}

.color-black,
.black {
    color: @color-black;
}

.bg-color-black,
.bg-black {
    background-color: @color-black;
}

.color-gray-darkest,
.gray-darkest {
    color: @color-gray-darkest;
}

.bg-color-gray-darkest,
.bg-gray-darkest {
    background-color: @color-gray-darkest;
}

.color-gray-darker,
.gray-darker {
    color: @color-gray-darker;
}

.bg-color-gray-darker,
.bg-gray-darker {
    background-color: @color-gray-darker;
}

.color-gray-dark,
.gray-dark {
    color: @color-gray-dark;
}

.bg-color-gray-dark,
.bg-gray-dark {
    background-color: @color-gray-dark;
}

.color-gray,
.gray {
    color: @color-gray;
}

.bg-color-gray,
.bg-gray {
    background-color: @color-gray;
}

.color-gray-light,
.gray-light {
    color: @color-gray-light;
}

.bg-color-gray-light,
.bg-gray-light {
    background-color: @color-gray-light;
}

.color-gray-lighter,
.gray-lighter {
    color: @color-gray-lighter;
}

.bg-color-gray-lighter,
.bg-gray-lighter {
    background-color: @color-gray-lighter;
}

.success {
    color: @brand-success;
}

.info {
    color: @brand-info;
}

.warning {
    color: @brand-warning;
}

.discount {
    color: @color-discount;
}

.font-size-xsmall {
    font-size: @font-size-xsmall;
}

.font-size-small {
    font-size: @font-size-small;
}

.font-size-base {
    font-size: @font-size-base;
}

.font-size-large {
    font-size: @font-size-large;
}

.font-size-intro {
    font-size: @font-size-intro;
}

.make-font-smaller {
    font-size: .75em;
}

// Interface
.vertical-center {
    .vertical-center();
}

.divider {
    .divider(top);
}

.divider-right {
    .divider(right);
}

.divider-thick {
    .divider(top;@divider-heavy; @color-black);
}

.divider-bottom {
    .divider(bottom);
}

.divider-thick-bottom {
    .divider(bottom;@divider-heavy;@color-black);
}

.loading {
    .loading(2em, .5em, @color-gray, .6);
}

body.loading:after {
    position: fixed;
}

.no-animation .loading {
    .loading-polyfill();
}

.margin-small {
    margin-bottom: @margin-small;
}

.margin-base {
    margin-bottom: @margin-base;
}

.margin-large {
    margin-bottom: @margin-large;
}

.margin-xlarge {
    margin-bottom: @margin-xlarge;
}

.margin-top-none {
    margin-top: 0;
}

.padding-small {
    padding-bottom: @margin-small;
}

.padding-base {
    padding-bottom: @margin-base;
}

.padding-large {
    padding-bottom: @margin-large;
}

.padding-xlarge {
    padding-bottom: @margin-xlarge;
}

.evo-gradient {
    .evo-gradient();
}

.hovergrow {
    .hovergrow();
}

.btn-close {
    .btn-close-mixin();
}

.link-super {
    .link-super();
}

.link-uber {
    .link-uber();
}

.link-more {
    .more-arrow();
}

.link-more-down {
    .more-arrow(3px; @color-green; down);
}
// Layout
.clear {
    clear: both;
}

.full-width-container {
    .full-width-container-mixin();
}

.container-full {
    .container-full();
}

.container-regular {
    .container-regular();
}

.container-md {
    .container-md();
}

.container-sm {
    .container-sm();
}

.evo-logo-inline {
    .evo-logo-inline();
}

.component-margin {
    .component-margin();
}

.component-padding {
    .component-padding();
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.stacked-section {
    .stacked-section();
}

.object-fit {
    .object-fit();
}