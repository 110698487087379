﻿@import '../framework/imports.less';

.flexslider {
    overflow: hidden;
}

.flex-direction-nav a {
    .global-arrow-mixin();
    .translate3d(100px, 0, 0); //hide by default
    .no-touch #wrapper .flexslider:hover & {
        .translate3d(0, 0, 0); //show on hover
        .opacity(1);
    }

    &.flex-prev {
        .global-previous-arrow();
        -ms-transform: translate3d(-100px, 0, 0) rotate(180deg);
        -moz-transform: translate3d(-100px, 0, 0) rotate(180deg);
        -webkit-transform: translate3d(-100px, 0, 0) rotate(180deg);
        transform: translate3d(-100px, 0, 0) rotate(180deg);

        .no-touch #wrapper .flexslider:hover & {
            -ms-transform: translate3d(0, 0, 0) rotate(180deg);
            -moz-transform: translate3d(0, 0, 0) rotate(180deg);
            -webkit-transform: translate3d(0, 0, 0) rotate(180deg);
            transform: translate3d(0, 0, 0) rotate(180deg);
        }
    }
}

.hero-banner .flex-control-nav {
    bottom: -29px; /*seeing this rendering differently in different browsers -30px is ok too TLF*/
    @media (min-width: @screen-sm) {
        bottom: -39px;
    }
}

.hero-banner .flex-viewport {
    height: 100%;

    .slides,
    .slides li {
        height: 100%;
    }

    .flex-slide-img {
        height: 100% !important;
        object-fit: cover;
    }
}