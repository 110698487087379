@import 'imports.less';

//add all common site stuff here.
// We basically have been moving everything out of here and into the layout pages, etc. Will keep it for now. JJM

//webfont antialiasing
html {
    *, input, textarea, select {
        -webkit-font-smoothing: antialiased;
    }
}

// //Layout
.body-content {
    position: relative;
    z-index: @body-content-z-index;
    padding-bottom: @margin-xlarge;
}

.single-column {
    .make-xs-column(12);
    .make-md-column(8);
    .make-md-column-offset(2);

    @media (max-width:@screen-sm-max) {
        padding: 0;
    }

    .modal & {
        margin: 0;
        width: 100%;
    }
}

.form-single-column {
    .make-sm-column(6);
    .make-sm-column-offset(3);
    margin-right: auto;
    margin-left: auto;

    @media (max-width:@screen-xs-max) {
        max-width: 400px;
        padding: 0;
    }
}

.head-centered {
    text-align: center;
}

.bg-img {
    display: block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.responsive-video {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.no-wrap {
    white-space: nowrap;
}
//secondary page container overlays with the hero background
.container-overlay {
    padding-top: 20px;
    padding-bottom: 50px;

    @media (max-width:@screen-lg-min) {
        margin-left: 20px;
        margin-right: 20px;
    }

    h1 {
        text-align: center;

        &:after {
            content: '';
            display: block;
            max-width: 175px;
            margin: 15px auto 22px auto;
            border-bottom: 5px solid @gray-light;
        }
    }
}

.component-header {
    text-align: center;
    margin-bottom: @margin-small;
    margin-top: @margin-small;
    position: relative;

    @media (min-width: @screen-sm) {
        margin-bottom: @margin-base;
    }
}

.intro-text {
    margin-top: @component-margin-base;
    margin-bottom: @component-margin-base;
    font-size: @font-size-intro;
    max-width: ((@screen-lg-min / 2) - @grid-gutter-width);
    margin-left: auto;
    margin-right: auto;

    @media (min-width: @screen-sm) {
        margin-top: @component-margin-large;
        margin-bottom: @component-margin-large;
    }

    h1, h2, h3, h4, h5 {
        &:first-child {
            margin-top: 0;
        }
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.component-title {
    margin: 0 auto;
    box-sizing: border-box;
    line-height: 1.1em;
    font-size: @component-title-sm;
    font-weight: @font-weight-bold;
    margin-bottom: 5px;

    @media (min-width: @screen-sm) {
        font-size: @component-title-lg;
        margin-bottom: initial;
    }

    > .comp-title-span {
        vertical-align: middle;
    }
}

.component-more-link {
    font-size: @component-title-sm * .625;
    font-weight: bold;
    line-height: 1em;
    text-transform: uppercase;
    .more-arrow();

    &:hover {
        text-decoration: none;

        &:after {
            border-color: @link-hover-color;
        }
    }

    @media (min-width: @screen-sm) {
        position: absolute;
        right: (@grid-gutter-width/2) + 2; //need to add a little for the rotated arrow
        top: @component-title-lg * .22;
        font-size: @component-title-lg * .625;
    }
}

.green-right-subhead-link {
    position: absolute;
    right: 0;
    top: 4px;
    font-size: 24px;
    padding-right: 10px;
    text-transform: uppercase;
    margin-right: @grid-gutter-width;
    color: @color-green;
    line-height: 1em;
    height: 24px;

    &:after {
        content: '';
        width: 11px;
        height: 11px;
        border-top: 3px solid @color-green;
        border-right: 3px solid @color-green;
        .rotate(45deg);
        position: absolute;
        bottom: 7px;
    }

    &:hover {
        text-decoration: none;
        color: @color-green-yellow;

        &:after {
            border-top: 3px solid @color-green-yellow;
            border-right: 3px solid @color-green-yellow;
        }
    }
}

.smidgen {
    position: relative;

    .smidgenPencil {
        position: absolute;
        left: 50%;
        top: 0;
        border: 1px solid #999;
        z-index: 1000;
        width: 12px !important;
        height: 12px !important;
        background: #fff;
        display: inline-block;
    }

    #konami & .smidgenPencil {
        left: 45%;
    }

    &[data-key="sale-banner"] .smidgenPencil {
        margin-left: -50px;
    }

    &[data-key="sale-banner-2"] .smidgenPencil {
        margin-left: -25px;
    }
}

.store-headband-html .smidgen .smidgenPencil,
.outlet-headband-html .smidgen .smidgenPencil {
    margin-left: -50px;
}

//control opacity
.fade-in {
    .opacity(1);
}

.fade-out {
    .opacity(0);
}

.inline {
    display: inline;
}

.no-animation {
    .transition(all 0s linear) !important;
}

/* Test Summary */
#testSummary {
    margin: 10px auto 60px;
    border-spacing: 0;
    display: inline-block;
}

#testSummary th,
#testSummary td {
    padding: 5px;
    border: 1px solid #DDD;
}

.modal-fix {
    overflow: hidden;
    height: 100%;
}

.sr-skip-link {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    background: @color-green-dark;
    color: #fff;

    &:focus {
        left: 0;
        width: auto;
        height: auto;
        padding: 8px;
        color: #fff;
        text-decoration: none;
        z-index: @masthead-skip-link-z-index;
    }
}

//used for WCAG accessibility compliance
.visually-hidden {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px) !important;
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}