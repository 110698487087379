@import 'imports.less';

//Mixins Vars

.mobile-nav-box-shadow() {
    .box-shadow(0 0 6px rgba(0,0,0,.3));
}

.navigation-icon(@code) {
    .icon-mixin();
    content: @code;
    font-size: 12px;
    line-height: 1em;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -.5em;
}

.navigation-divider() {
    content: '';
    left: 0;
    bottom: 0;
    position: absolute;
    width: 1px;
    height: 24px;
    background-color: @gray-light;
    z-index: @top-nav-link-z-index + 1;
}

//formatting for the links that is shared and gets reset at desktop
.navigation-link-format() {
    font-size: 14px;
    color: @color-black;
    padding-left: 15px;
    border-left: 3px solid @brand-primary;
    padding-right: 10px;

    @media (min-width: @navigation-breakpoint) {
        border-left: none;
        background: none;
    }
}

//-------------------------------------
// General formatting
//-------------------------------------

.subnavigation-wrapper {
    display: none;
}

//-------------------------------------
// Navigation Smidgen Formatting
//-------------------------------------

#hardcoded-navigation-smidgen {
    position: absolute;
}

//-------------------------------------
// Mobile Navigation Formatting
//-------------------------------------
@media (max-width: (@navigation-breakpoint - 1)) {
    .page {
        -webkit-transition: -webkit-transform .25s ease-in;
        -moz-transition: -moz-transform .25s ease-in;
        transition: transform .25s ease-in;
    }

    .navigation-active {
        height: 100%;
        overflow: hidden;

        body, .page {
            height: 100%;
            overflow: hidden;
        }

        .page {
            .translate(@mobile-navigation-offset,0);
            .box-shadow(0 0 6px rgba(0,0,0,.3));

            @media (min-width: @screen-xs-min) {
                .translate(@tablet-navigation-offset,0);
            }
        }
    }

    .mobile-nav-link {
        position: absolute;
        left: (@grid-gutter-width/2);
        top: @masthead-mobile-vertical-padding;
        line-height: @logo-height-xxs;
        color: @brand-primary;
        padding: 0 @mobile-element-inset;
        cursor: pointer;
        white-space: nowrap;
        font-size: 0;

        @media (min-width: @screen-xs) {
            line-height: @logo-height-xs;
        }
    }

    .mobile-nav-stripe-box {
        margin-right: 6px;
        vertical-align: middle;
        display: inline-block;
    }

    .mobile-nav-stripe {
        display: block;
        height: 2px;
        width: 20px;
        top: 0;
        background: @brand-primary;
        margin: 5px 0;
        position: relative;
        .transition(@transition-base);
    }

    .navigation-active {
        .mobile-nav-stripe:nth-child(2) {
            .opacity(0);
        }

        .mobile-nav-stripe:nth-child(1) {
            .rotate(45deg);
            top: 7px;
        }

        .mobile-nav-stripe:nth-child(3) {
            .rotate(-45deg);
            top: -7px;
        }
    }

    .mobile-nav-label {
        text-transform: uppercase;
        font-weight: @font-weight-regular;
        font-size: 13px;
        margin-top: 1px;
        vertical-align: middle;
        display: inline-block;
    }
}
//end max width media query

// just use one media query to hide them all

@media (min-width: @navigation-breakpoint) {
    .mobile-nav-link {
        display: none;
    }
}

//-------------------------------------
// formatting for primary navigation
//-------------------------------------
#navigation-wrapper {
    position: absolute;
    width: @mobile-navigation-offset;
    z-index: @mobile-navigation-z-index;
    height: 100%;
    background: @gray-lighter;

    @media (min-width: @screen-xs-min) {
        width: @tablet-navigation-offset;
    }

    a:hover,
    a:active,
    a:focus {
        text-decoration: none;
    }
}

@media (max-width: (@navigation-breakpoint - 1)) {
    #navigation-wrapper {
        height: 100%;
        left: -1200px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .navigation-visible #navigation-wrapper {
        left: 0;
    }

    #navigation-wrapper > .container {
        height: 100%;
    }
}

@media (min-width: @navigation-breakpoint) {
    #navigation-wrapper {
        top: @masthead-height-medium;
        width: 100%;
        height: 0;
        background-color: transparent;
        z-index: auto;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: @masthead-height-medium;
            background: url(//static.evo.com/content/www/framework/header/watercolor-bg-left.jpg),url(//static.evo.com/content/www/framework/header/watercolor-bg-right.jpg);
            background-repeat: no-repeat;
            background-position: 0 @utilities-height, 100% @utilities-height;
            background-size: 100px auto;
            background-color: @color-white;
            bottom: 0;
            left: 0;
            z-index: @navigation-wrapper-before-z-index;
            .box-sizing(content-box);
            .box-shadow(0 3px 4px 0px rgba(0,0,0,.25));
        }
    }

    #navigation-wrapper.active::after {
        .opacity(1);
    }

    //white screen background to block out the slide down nav

    .navigation-overlay {
        height: 0;
        box-shadow: 0 0 15px rgba(0,0,0,.4);
        z-index: @subnavigation-background-z-index;
        @transition-var: top .25s @transition-function .05s, height .25s ease-out;
        .transition(@transition-var);
        background: @color-white;
        max-width: @masthead-max-width;
        margin-left: @masthead-padding/2;
        margin-right: @masthead-padding/2;

        @media (min-width: @screen-lg) {
            margin-left: @masthead-padding;
            margin-right: @masthead-padding;
        }

        @media (min-width: @navigation-breakpoint-large) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .navigation-bg {
        height: 0;
        width: 100%;
        background: rgba(0,0,0,.6);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: @subnavigation-background-z-index - 1;
        @transition-var: opacity .25s @transition-function .05s, height 0s ease-out .35s;
        .transition(@transition-var);
    }

    #navigation-wrapper.active.navigation-overlay {
        top: 0 !important;
    }

    #navigation-wrapper.active .navigation-bg {
        height: 1000px;
        height: 160vh;
        opacity: 1;

        @transition-var: opacity .25s @transition-function .05s, height 0s linear 0s;
        .transition(@transition-var);

        > .container {
            padding: 0;
            height: 0;
        }
    }
}

@media (min-width: @screen-lg) {
    #navigation-wrapper {
        overflow-y: visible;
        top: @masthead-height-large;

        &:before {
            height: @masthead-height-large;
        }
    }
}

.navigation-offset {
    z-index: @mobile-list-z-index;
    position: relative;

    @media (min-width: @navigation-breakpoint) {
        z-index: @top-nav-item-z-index;
        position: static;
        margin-right: @masthead-padding / 2;
        margin-left: @masthead-padding / 2;
    }

    @media (min-width: @screen-lg-min) {
        margin-left: @masthead-padding + 60 + @logo-width-large;
        margin-right: @masthead-padding;
    }

    @media (min-width: @navigation-breakpoint-large) {
        max-width: @masthead-max-width;
        margin-left: auto;
        margin-right: auto;
        padding-left: @logo-width-large + (@masthead-padding * 2);
        padding-right: @masthead-padding;
    }
}

#navigation {
    padding: 0;
    margin: 0;
    display: block;
    list-style-type: none;
    position: relative;
    .transition(@transition-base);

    @media (max-width: (@navigation-breakpoint - 1)) {
        box-shadow: 0 0 10px rgba(0,0,0,.4);
    }

    @media (min-width: @navigation-breakpoint) {
        .flex-display(flex);
        .flex-wrap(nowrap);
        .flex-direction(row);
        .justify-content(space-between);
        background: none;
        position: static;
        height: @navigation-height;
        margin-top: (@navigation-height * -1);
    }
}

@media (min-width: @navigation-breakpoint) {
    .top-nav-item {
        float: left;
        .flex-grow(1);
        height: @navigation-height;
        border-left: 1px solid @gray-light;
    }

    .top-nav-link.acc-active:after,
    .top-nav-item.active .top-nav-link:after {
        content: '';
        border-bottom: 3px solid @brand-primary;
        z-index: @top-nav-link-z-index + 1;
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
    }
}

.top-nav-has-children .top-nav-link:after {
    @media (max-width: (@navigation-breakpoint - 1)) {
        .navigation-icon("\e60e");
    }
}

.top-nav-item-secondary {
    [class^="icon-"],
    [class*=" icon-"] {
        color: @brand-primary;
        font-size: 16px;
        vertical-align: baseline;
        margin-right: .3em;
    }
}

.top-nav-mobile {    
    @media (min-width: @navigation-breakpoint) {
        display: none;
    }
}

.top-nav-mobile.top-nav-culture {
    border-bottom: 1px solid @color-black;
}

.featured_nav a {
    font-family: @font-family-loaded;
}

#navigation a {
    color: @color-black;
    display: block;
    position: relative;

    @media (max-width: (@navigation-breakpoint - 1)) {
        .divider(bottom);
        .transition(all @transition-duration @transition-function .1s); /*last number is the delay*/
        padding: .65em 10px;
    }

    @media (min-width: @navigation-breakpoint) {
        padding-left: 0;
        padding-right: 0;
    }
}

#navigation a.top-nav-link {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 5px;
    align-items: center;

    @media (min-width: @navigation-breakpoint) {
        z-index: @top-nav-link-z-index;
        position: relative;
        white-space: nowrap;
        justify-content: center;

        &:before {
            .navigation-divider();
        }
    }
}

#navigation .top-nav-link-results {
    .navigation-link-format();
    font-weight: @font-weight-regular;
    background: #fff;

    @media (min-width: @navigation-breakpoint) {
        padding-left: 6px;
        padding-right: 6px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: @navigation-height;
        text-align: center;
    }

    @media (min-width: @screen-lg) {
        font-size: 13px;
    }

    @media (min-width: @navigation-breakpoint-large) {
        font-size: 14px;
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: @color-black;
    }
}

#navigation .top-nav-link-sale {
    color: @color-red;

    &:hover {
        color: @color-red;
    }

    border-left: 3px solid @color-red;

    @media (min-width: @navigation-breakpoint) {
        padding-left: 15px;
        padding-right: 15px;
        border-left: none;
    }

    @media (max-width: @screen-md) {
        .icon-price-tag2 {
            display: none;
        }
    }
}

.top-nav-item-sale{
    .flex-grow(0);
}

.top-nav-item:hover .top-nav-link-sale:after {
    border-bottom-color: @color-red !important;
}

.topnav-link-phone {
    font-size: 18px;
}

.top-nav-link-callout .icon-evo-text {
    font-size: 1.12em;
    margin-top: .07em;
}

.top-nav-link-callout span {
    vertical-align: top;
    display: inline-block;
}
#navigation .top-nav-link-sale {
    display: flex;
    align-items: center;
    text-align: left;
    
    span {
        flex: 1 1 auto;
    }
    
    .icon-sale {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    
    @media (min-width: @navigation-breakpoint) {
        .icon-sale {
            margin-right: 5px;
        }
    }

    @media (min-width: 1200px) {
        .icon-sale {
            margin-right: 10px;
        }
    }
}

#navigation .top-nav-link-community-hub {
    font-size: 16px;
    color: @color-dark-teal;
    display: flex;
    align-items: center;
    text-transform: none;
    text-align: left;
    border-left: 3px solid @color-dark-teal;

    .icon-hub {
        width: 30px;
        flex: 0 0 30px;
        margin-right: 10px;
    }

    span {
        flex: 1 1 auto;
    }

    &:before {
        content: none;
    }

    @media (max-width: ( @navigation-breakpoint - 1)) {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @media (min-width: @navigation-breakpoint) {
        font-size: 13px;
        border-left: none;

        .icon-hub {
            width: 20px;
            margin-right: 5px;
        }
    }

    @media (min-width: 1200px) {
        font-size: 16px;
        padding-left: 15px;

        .icon-hub {
            width: 30px;
            margin-right: 10px;
        }
    }
}

@media (max-width: ( @navigation-breakpoint - 1)) {
    #navigation .top-nav-link-callout {
        text-transform: uppercase;
        background-color: @color-gray-darkest;
        text-align: center;
        margin-top: @margin-small;
        color: @color-white;
        font-size: 20px;
        font-weight: bold;
        margin-left: 10px;
        margin-right: 10px;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: .75em;
        padding-bottom: .75em;
        border-bottom: none;
    }

    #navigation .top-nav-link-subtitle {
        display: block;
        text-transform: none;
        font-size: @font-size-base;
        font-weight: normal;
    }

    #navigation-wrapper .top-nav-link-trip {
        padding: 0;
        background: none;
    }

    .top-nav-link-trip span {
        background: url(//static.evo.com/content/cms/menus/main/evotrip-mobile-menu-callout.jpg) no-repeat 0 50%;
        background-size: cover;
        width: 100%;
        padding: 0 0 32.098765432% 0;
        font-size: 0;
    }

    #navigation-wrapper .top-nav-link-allthings {
        display: none;
    }

    #navigation-wrapper .top-nav-link-locations {
        background-image: url(//static.evo.com/content/cms/all-things-evo/evo-locations-mobile-callout.jpg);
    }
}

@media (min-width: @navigation-breakpoint) {
    .top-nav-allthings {
        display: block;
        height: @navigation-height;
    }

    #navigation .top-nav-link-allthings {
        text-transform: uppercase;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        text-align: center;
        font-size: 14px;
        height: 100%;
        font-weight: @font-weight-regular;

        &:hover {
            color: @color-black;
        }

        span {
            line-height: @navigation-height;
            // need to add the devider here because it won't work for the last one
            &:after {
                .navigation-divider();
                left: auto;
                right: 0;
            }
        }

        .icon-evo-text {
            overflow: hidden;
            display: inline-block;
            color: transparent;
            margin-top: -1px;

            &:before {
                text-indent: 0;
                color: @color-black;
            }
        }
    }
}
//-------------------------------------
// Sub Navigation
//-------------------------------------
.subnav-outer {
    position: absolute;
    display: none;
    width: 100%;
    z-index: @subnavigation-z-index;
    .transition(opacity .2s ease-out .3s);

    @media (max-width: (@navigation-breakpoint - 1)) {
        left: 100%;
        top: 0;
        border-top: 2px solid #fff;
        background: #fff;
    }

    @media (min-width: @navigation-breakpoint) {
        @transition-var: opacity .25s ease-out .05s, top 0s linear .35s;
        .transition(@transition-var);
        left: 0;
        right: 0;
        height: 0;
        top: -1000px;
        .opacity(0);
        // padding-bottom: 30px;
    }

    a {
        color: #000;
        display: block;
    }
}

@media (min-width: @navigation-breakpoint) {
    // make subnavs display blockquote when hovering input the navigation shop.
    .navigation-shop.active .subnav-shop {
        display: block;
    }

    //hover effect for subnavigation
    .top-nav-item.active .subnav-shop {
        // display: block;
    }

    //focus
    .top-nav-link.acc-focus + .subnav-shop {
        display: none;
    }

    //hover
    .top-nav-item.active .subnav-outer {
        .opacity(1);
        top: 0;
        @transition-var: opacity .25s ease-out .3s, top 0s linear .35s;
        .transition(@transition-var);
    }

    //focus
    .top-nav-link.acc-active + .subnav-outer.acc-active {
        display: block;
        top: 0;
        .opacity(1);

        .subnav {
            border-bottom: @border-light;
            border-left: @border-light;
            border-right: @border-light;
            box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
        }
    }

    #navigation .subnav a {
        &:active,
        &:hover,
        &:focus {
            color: @color-green-dark;
        }
    }
}

@media (max-width: (@navigation-breakpoint - 1)) {
    .subnav-show .subnav-shop {
        display: block;
    }

    .subnav-back-link {
        font-weight: @font-weight-regular;

        .icon-arrow-left-medium {
            font-size: 12px;
        }
    }

    .subnav.container {
        .flex-display(flex);
        .flex-direction(column);
        margin: 0;
    }
}

#navigation .subnav-banner {
    min-height: 100px;
    .flex-display(flex);
    .flex-direction(column);
    .justify-content(center);
    .align-items(center);

    .btn {
        padding: 3px 12px;
        border: 2px solid #000;

        &:hover {
            color: @color-black;
        }
    }

    &:after {
        content: '';
        margin-top: 2px;
    }

    @media (max-width: (@navigation-breakpoint - 1)) {
        border-left: 3px solid @brand-primary;

        &:after {
            height: 15px;
        }
    }

    @media (min-width: @navigation-breakpoint ) {
        margin-left: (@masthead-padding / 2) * -1;
        margin-right: (@masthead-padding / 2) * -1;
    }

    @media (min-width: @screen-lg ) {
        margin-left: @masthead-padding * -1;
        margin-right: @masthead-padding * -1;
    }

    .btn {
        color: #000;
        border-color: #000;
    }

    .icon-evo-text {
        vertical-align: bottom;
        display: inline-block;
        font-size: 1.08em;
    }
}

.subnav-close-btn {
    display: none;
}

.touch .subnav-close-btn,
.navigation-shop[data-subnav-event=click] .subnav-close-btn {
    @media (min-width: @navigation-breakpoint ) {
        width: 36px;
        height: 36px;
        position: absolute;
        right: 0;
        top: 0;
        background: rgba(0,0,0,.8);
        z-index: 1000;
        display: block;
        cursor: pointer;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 9px;
            border-left: 2px solid #fff;
            height: 20px;
        }

        &:before {
            .rotate(-45deg);
            right: 17px;
        }

        &:after {
            .rotate(45deg);
            right: 17px;
        }
    }
}

#navigation .subnav-banner-link {
    font-size: 20px;

    a {
        border: none;
        padding: 0;
    }
}

.subnav-banner-header {
    min-height: 100px;
    color: #000000;
    .flex-display(flex);
    .flex-direction(column);
    .justify-content(center);
    .align-items(center);

    .subnav-banner-link {
        margin: 0 0 .3em 0;
    }
}

.subnav-callout {
    .component-margin {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media (max-width: (@navigation-breakpoint - 1)) {
    .subnav-featured-column {
        .flex-order(0);
    }

    .subnav-all-things-evo .subnav-featured-column {
        .flex-order(0);
    }

    .subnav-callout {
        .flex-order(3);
    }

    .hide-for-mobile-nav {
        display: none;
    }
}

.top-nav-item ul {
    padding: 0;
    list-style-type: none;
}

.subnav-active #navigation {
    @media (max-width: (@navigation-breakpoint - 1) ) {
        .translate(-100%,0);
    }
}

ul.subnav {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    @media (min-width: @navigation-breakpoint) {
        display: block;
        max-width: @masthead-max-width;
        margin-left: @masthead-padding/2;
        margin-right: @masthead-padding/2;
        padding: 0 @masthead-padding/2 @masthead-padding/2 @masthead-padding/2;
        .clearfix();
    }

    @media (min-width: @screen-lg) {
        padding: 0 @masthead-padding @masthead-padding @masthead-padding;
        margin-left: @masthead-padding;
        margin-right: @masthead-padding;
    }

    @media (min-width: @navigation-breakpoint-large) {
        margin-left: auto;
        margin-right: auto;
    }
}

.top-nav-mobile {
    a {
        .navigation-link-format();
    }
}

#navigation .subnav-featured-column .featured_nav {
    h2 {
        .navigation-link-format();
        font-weight: @font-weight-regular;
        margin: 0;
        padding-top: .65em;
        padding-right: 10px;
        padding-bottom: .65em;
        padding-left: 10px;

        @media (max-width: @navigation-breakpoint) {
            .divider(bottom);
        }

        @media (min-width: @navigation-breakpoint ) {
            line-height: 1.45;
            padding: 0 0 .5em 0;
            font-size: @font-size-base;
            font-weight: @font-weight-bold;
        }
    }

    ul {
        background: @color-gray-lighter;

        @media (min-width: @navigation-breakpoint ) {
            background: none;
        }

        li {
            padding: 0;

            a {
                .navigation-link-format();
                display: block;
                font-weight: @font-weight-bold;
                padding-top: .65em;
                padding-right: 10px;
                padding-bottom: .65em;
                padding-left: 10px;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

//columns for desktop
@media (min-width: @navigation-breakpoint ) {
    .subnav-column {
        float: left;
        padding: 0 (@grid-gutter-width/2);
        width: 15%;

        &.first, //in case nth child does not work.
        &:nth-child(3) { //accounts for header and back button
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        ul {
            padding-bottom: 1em;
        }

        ul li {
            margin-bottom: .5em;
        }
    }

    .subnav-column-ate {
        width: 20%;
        padding-left: 10px !important;
    }

    //custom blog post formatting
    .subnav-column-blog {
        width: 30%;

        .post-thumb-container {
            padding: 0;
        }

        .post-thumb-row {
            margin-left: 0;
            margin-right: 0;
        }

        hr {
            display: none;
        }

        .post-thumb {
            width: 100%;
            overflow: hidden;
            margin-bottom: 20px;
            padding: 0;
            max-height: 190px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .post-thumb-excerpt {
            display: none;
        }

        .post-thumb-title {
            font-size: 18px;
        }
    }

    .subnav-featured-column {
        width: 18%;
    }

    #navigation .subnav .subnav-featured-column .featured_nav {
        h2 {
            font-size: 14px;
        }

        ul {
            border-top: @border-light;
            padding: 0;
            margin: 0;
        }

        ul li a {
            padding: .5em 0;
            font-weight: @font-weight-bold;
            color: @color-green-dark;
            border-bottom: @border-light;

            &:nth-child(2) {
                border-top: @border-light;
            }
        }
    }

    .top-nav-allthings .subnav {
        padding-top: @grid-gutter-width;
        min-height: 486px;
    }

    .subnav-all-things-evo .featured_list ul {
        border-top: 0 !important;
    }

    .subnav-categories {
        width: 54%;
    }

    .subnav-categories .subnav-category-list {
        -moz-column-width: 33%;
        -webkit-column-width: 33%;
        column-width: 33%;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: @grid-gutter-width;
        -webkit-column-gap: @grid-gutter-width;
        column-gap: @grid-gutter-width;
    }

    .subnav-callout {
        width: 28%;

        @media (min-width: @navigation-breakpoint ) {
            min-height: 285px;
        }
    }
}

//updated layout for large desktop devices.
@media (min-width: @navigation-breakpoint-large ) {
    .subnav-featured-column {
        width: 15%;
    }

    .subnav-column-ate {
        width: 20%;
    }

    .subnav-column-blog {
        .post-thumb-excerpt {
            display: block;
        }

        .post-thumb {
            height: 190px;
        }
    }

    .subnav-categories {
        width: 60%;
    }

    .subnav-categories .subnav-category-list {
        -moz-column-width: 25%;
        -webkit-column-width: 25%;
        column-width: 25%;
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }

    .subnav-callout {
        width: 25%;
    }
}

.subnav-category-item {
    @media (min-width: @navigation-breakpoint ) {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

#navigation .subnav-results-single,
#navigation .subnav-results-more {
    font-weight: @font-weight-regular;

    @media (min-width: @navigation-breakpoint ) {
        margin-bottom: .5em;
        padding-bottom: .5em;
        border-bottom: 2px solid @gray-light;
        font-weight: @font-weight-bold;
        color: @brand-primary;

        &:after {
            content: "\e60e";
            font-family: icomoon;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
        }
    }
}

#navigation .subnav-column .subnav-results-more {
    @media (max-width: (@navigation-breakpoint - 1) ) {
        .plus-to-close-icon();
    }
}

ul.subsubnav-category-list {
    display: none;

    @media (min-width: @navigation-breakpoint ) {
        display: block;
    }
}

.navigation-callout-text {
    @media (min-width: @navigation-breakpoint ) {
        display: none;
    }
}

.navigation-callout-image {
    @media (max-width: (@navigation-breakpoint - 1)) {
        display: none;
    }
}

#navigation .subsubnav-category-list a {
    @media (max-width: (@navigation-breakpoint - 1)) {
        background: #f3f3f3;
        padding-left: 15px;
    }
}

ul.subnav-brands {
    list-style: none;

    .subnav-brands-title {
        margin: 0;
        padding: 36px 0 12px;
    }

    .subnav-banner-brands {
        display: none;
    }

    .subnav-featured-brands a {
        padding: 0 !important;
        text-align: center;
    }

    .subnav-brands-alphabet a {
        color: @color-green !important;
        font-size: @font-size-large;
        font-weight: @font-weight-bold;
    }

    @media (max-width: (@navigation-breakpoint - 1)) {
        .separator-shadow::after {
            display: none;
        }
    }

    @media (min-width: (@navigation-breakpoint)) {
        li {
            &.featured-brands {
                display: block;
                margin: @margin-medium 0;
                text-align: center;

                .subnav-featured-brands {
                    a {
                        display: inline-block !important;
                    }
                }
            }

            &.subnav-banner-brands {
                margin: 0 -10px;
            }

            .subnav-brands-alphabet {
                display: block;
                padding: @margin-small;
                text-align: center;

                a {
                    display: inline-block !important;
                    padding: 0 8px !important;
                }
            }

            &.subnav-banner-brands {
                display: block;
            }
        }
    }

    @media (min-width: @screen-lg) {
        li.subnav-banner-brands {
            margin: 0 -20px;
        }
    }
}

.accessible-subnav {
    display: none;
}