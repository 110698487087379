@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/modals.less';

.modal-content {
  border-radius: 0;
}

.modal-content:after {
  content: " ";
  display: table;
  clear: both;
}

.modal-body {
  .clearfix();
  padding: 0 @margin-base @margin-base;
  white-space: normal;
}

//added with JS
.modal-ios {
  position: absolute;
  overflow: visible;
}

.modal-footer {
  .divider(@pos: top);
}

@media (min-width: @screen-xs-min) {
  .modal-form {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.modal {
  white-space: nowrap;
  font-size: 0;
  text-align: center;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    margin-left: -1px;
    height: 100%;
  }
}

.modal-dialog {
  font-size: @font-size-base;
  margin: 13px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  max-width: 90%;
  .translate3d(0; 0; 0);
  width: (@container-large-desktop / 2) - @grid-gutter-width;
}

@media (min-width: @screen-xs-min) {
  .modal-dialog {
    margin: 30px;
  }
}

@media (min-width: @screen-xs-min) {
}

.modal-btn-close {
  position: absolute !important; // to override position relative in mixin .btn-close-mixin
  z-index: 300;
  top: -13px;
  right: -13px;
  .opacity(1);
  .transition(@transition-base);
}

//---------Modal Ajax-----------------------

.modal-ajax {
  .modal-dialog {
    width: 760px;
  }

  .modal-body {
    overflow: auto;

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child {
      margin-top: 0;
    }

    > div {
      width: auto !important;
    }

    img {
      height: auto !important;
    }

    iframe {
      max-width: 100%;
    }
  }

  .container {
    width: auto !important;
  }
}

// Modal table styles - copies .spec-table styles
.modal-ajax .modal-body table {
  width: 100% !important;
  clear: both;
  margin-bottom: 25px;
  background: #fff;

  thead {
    background: fadeOut(@gray-light, 30%);
  }

  th,
  td {
    padding: .4em 1em;
    border-right: 2px solid #fff;

    &:last-child {
      border-right: none;
    }
  }

  th {
    text-align: left;
  }

  tr:nth-of-type(2n) {
    th,
    td {
      background: fadeOut(@gray-light, 30%);
    }
  }
}

.modal-gear-condition .modal-dialog {
  width: 764px;
}

//---------Modal Images-----------------------
.modal-image-wrapper .modal-dialog {
  max-width: 90%;
  width: auto;
}

.modal-image-wrapper .modal-content {
  background: transparent;
  .transition(@transition-base);
  .opacity(1);
}

.modal-loading {
  .modal-dialog {
    .loading(2em; 5px; #fff; .8);

    &:before {
      display: none;
    }

    .modal-btn-close {
      .opacity(0);
    }

    .modal-content {
      .opacity(0);
    }
  }
}

//support for viewport height units.

.modal-image {
  max-height: 85vh;
}

.modal-header {
  border-bottom: none;
  border: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;

  &:after {
    content: "";
    background-image: url(//static.evo.com/content/www/components/separators/separator-small.png);
    padding-bottom: @separator-height;
    background-repeat: no-repeat;
    background-position: 50% top;
    margin-bottom: 5px;
    display: block;
    background-size: contain;

    @media (min-width: @screen-sm-min) {
      background-image: url(//static.evo.com/content/www/components/separators/separator-1.png);
    }
  }
}

.modal-title {
  margin-bottom: 10px;
  color: #000;
}

//----------Modal videos--------------
.modal-video .modal-dialog {
  width: 90%;
  max-width: 1280px;
}

.modal-video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /*405/720*/
}

.modal-video-iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.video-img-link {
  max-width: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
  margin-bottom: @margin-small;

  &:hover {
    color: @color-green;

    .video-icon {
      background-color: rgb(255, 255, 255);
    }
  }

  @media (min-width: @screen-sm) {
    margin-bottom: @margin-base;
  }
}

.video-icon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .vertical-center();
}

.video-icon {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 50px;
  position: relative;
  .transition(@transition-base);

  &:before {
    font-size: 60px;
    position: absolute;
    top: 22px;
    left: 25px;
  }
}

.cms-modal-info {
  .modal-content {
    padding-bottom: @component-margin-base;

    h1, h2 {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}