@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/type.less';

html {
    font-size: @font-size-base;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    margin-top: 1em;
    line-height: 1.1em;
    margin-bottom: .5em;
    font-family: @font-family-loaded;
    font-weight: 800;
}

h6, .h6 {
    margin: 5px 0;
}

h1, .h1, h2, .h3, h3, .h3 {
    margin-top: 1.5em;
    margin-bottom: .5em;
}

p {
    margin-bottom: 1.42em;
    line-height: 1.42em;
}

ul {
    margin-bottom: 1.6em;
}

.font-amsi {
     & {
        font-family: @font-family-condensed-loaded;
    }
}

.header-casual {
    text-transform: none;
}

.heading-banner {
    .rem(48);
}

h1,
.h1 {
    // font-size: @font-size-h1 * .6;
    .rem(28);

    @media (min-width: @screen-md-min) {
        // font-size: @font-size-h1 * .8;
        .rem(36);
    }

    @media (min-width: @screen-lg-min) {
        // font-size: @font-size-h1;
    }
}

h2,
.h2 {
    // font-size: @font-size-h1 * .6;
    .rem(24);

    @media (min-width: @screen-md-min) {
        // font-size: @font-size-h1 * .8;
    }

    @media (min-width: @screen-lg-min) {
        // font-size: @font-size-h1;
    }
}

h3,
.h3 {
    .rem(20);
}

h4,
.h4 {
    font-size: 18px;

    @media (min-width: @screen-md-min) {
        font-size: @font-size-h4;
    }
}

.heading-1, .heading-2, .heading-3 {
    color: @color-dark-teal;
    margin: 0;

     & {
        font-family: @font-family-condensed-loaded;

        &.font-mont {
            font-family: @font-family-loaded;
            font-weight: 700;
        }
    }
}

.heading-1 {
    .rem(50);
    line-height: 1;

    & + p {
        margin-top: 16px;
    }

    @media (min-width: @screen-sm) {
        .rem(72);
    }
}

.heading-2 {
    .rem(32);
    line-height: 1;

    & + p {
        margin-top: 16px;
    }

    @media (min-width: @screen-sm) {
        .rem(56);
    }
}

.heading-3 {
    .rem(24);
    line-height: 1;

    & + p {
        margin-top: 16px;
    }

    @media (min-width: @screen-sm) {
        .rem(48);
    }
}

.heading-4 {
    .rem(20);
    line-height: 1.25;
    color: @color-black;
    margin: 0;

     & {
        font-family: @font-family-loaded;
        font-weight: 700;
    }

    & + p {
        margin-top: 14px;
    }

    @media (min-width: @screen-sm) {
        .rem(32);

        & + p {
            margin-top: 24px;
        }
    }
}

.heading-5 {
    .rem(18);
    line-height: 1;
    color: @color-black;
    margin: 0;

     & {
        font-family: @font-family-loaded;
        font-weight: 700;
    }

    & + p {
        margin-top: 16px;
    }

    @media (min-width: @screen-sm) {
        .rem(24);
    }
}

.paragraph-regular {
    .rem(14);
    line-height: 1.71;
    color: @color-gray-dark;

    @media (min-width: @screen-sm) {
        .rem(16);
        line-height: 1.75;
    }
}

.paragraph-large {
    .rem(16);
    line-height: 1.75;
    color: @color-gray-dark;

    @media (min-width: @screen-sm) {
        .rem(24);
        line-height: 1.67;
    }
}


 .header-light {
    font-weight: @font-weight-regular;
}

code,
pre {
    background: @gray-lighter;
    color: @gray-dark;
    padding: .3em;
    margin-left: .2em;
    margin-right: .2em;
    border-radius: 2px;
}

strong,
b {
    font-weight: @font-weight-bold;
}
// Blockquotes
blockquote {
    margin: 10px auto 0px auto;
    padding: 10px 0px 0px 0px;
    font-size: @blockquote-font-size * .83333333;
    border-left: 0;
    color: @color-black;
    line-height: 1.6em;
    max-width: @intro-text-max-width;
    text-align: center;
    position: relative;
    z-index: @blockquote-z-index;

    @media (min-width: @screen-md) {
        font-size: @blockquote-font-size;
        line-height: 1.3em;
    }

    &:before {
        display: block;
        position: absolute;
        content: @open-quote-stylized;
        font-size: 220px;
        color: @color-gray-lighter;
        top: @margin-large + 10;
        left: 0;
        z-index: -1;
    }
}

.on-sale,
.discount {
    color: @color-red;
}

.line-through,
.slash {
    color: @color-gray-dark;
    text-decoration: line-through;
}

.link-icon {
    [class^="icon-"], [class*=" icon-"] {
        text-decoration: none;
        font-size: .857em; /*14/12*/
        margin-right: .3em;
        margin-left: .3em;
        display: inline-block;
    }

    :hover {
        text-decoration: none;

        span {
            text-decoration: underline;
            vertical-align: middle;
        }

        [class^="icon-"], [class*=" icon-"] {
            text-decoration: none;
        }
    }
}

.tab-promo {
    overflow: hidden;
    background-color: #000;
    color: #fff;
    position: relative;
    display: inline-block;
    padding: .3em 10px .3em 20px;
    text-transform: uppercase;
    font-size: @font-size-small;

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: #fff;
        position: absolute;
        left: 8px;
        top: 50%;
        margin-top: -3px;
    }

    &:after {
        @tab-width: 2em;
        position: absolute;
        left: ((@tab-width * 1.3) * -1);
        top: 50%;
        margin-top: @tab-width * -1;
        content: '';
        display: block;
        border-top: @tab-width solid #fff;
        border-right: @tab-width solid transparent;
        border-left: @tab-width solid #fff;
        border-bottom: @tab-width solid #fff;
    }
}

.link-icon:hover {
    text-decoration: none;

    span {
        text-decoration: underline;
    }

    > [class^="icon-"], [class*=" icon-"] {
        text-decoration: none;
    }
}

.caption {
    text-align: right;
    font-weight: @font-weight-regular;
    color: lighten(@gray-dark, 5%);
    font-size: ceil(@font-size-base * 1.25);
}

.font-italic {
    font-style: italic;
}

.color-white {
    color: @color-white;
}