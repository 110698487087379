@import 'variables.less';
@import "../../libs/bootstrap/mixins.less";

//why does bootstrap not have this?

.clear-list-formatting(@padding: 0; @margin: 0) {
    list-style-type: none;
    padding: @padding;
    margin: @margin;
}

//calculates rem values based on pixels
.rem(@num) {
    @remValue: @num/@font-size-base-int;
    font-size: ~"@{remValue}rem";
}

//loading animation
.loading(@size: 2.5em, @border: 5px, @color: @color-gray-light, @opacity: .8) {
    cursor: wait;
    position: relative;

    &:before {
        content: '';
        opacity: @opacity;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background: #fff;
        z-index: (@mixin-loading-animation-after - 1);
    }

    &:after {
        content: '';
        background-color: rgba(0,0,0,0);
        border-radius: @size;
        .animation(loading .8s infinite linear);
        display: block;
        border: @border solid @color;
        border-top: @border solid rgba(0,0,0,0);
        border-left: @border solid rgba(0,0,0,0);
        width: @size;
        height: @size;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: @mixin-loading-animation-after; //20
        margin-top: ((@size/2)*-1);
        margin-left: ((@size/2)*-1);
    }
}

.loading-polyfill() {
    &:after {
        border: none;
        background: transparent url(//static.evo.com/content/www/framework/ajax-loader.gif) no-repeat 50% 50%;
        width: 32px;
        height: 32px;
    }
}

//mandatory height on the parent element
.vertical-center() {
    white-space: nowrap;

    & > span,
    & > header,
    & > footer,
    & > img,
    & > a,
    & > div,
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
    }

    &:after {
        display: inline-block;
        content: '';
        height: 100%;
        margin-left: -.3em; //for the letter spacing
        width: 1px;
        vertical-align: middle;
    }
}

.alert-icon(@alert-size: 50px; @left: 25px) {
    &:after {
        content: '';
        background: url(//static.evo.com/content/www/framework/alert-triangle-icon-retina.png) no-repeat 0 0;
        background-size: 100%;
        display: block;
        position: absolute;
        width: @alert-size;
        height: @alert-size;
        font-size: 1.4em;
        top: 50%;
        line-height: 1em;
        margin-top: ((@alert-size/2) * -1);
        margin-right: ((@alert-size/2) * -1);
        left: @left;
    }
}

// Retina images
//
// Short retina mixin for setting background-image and -size

.img-retina(@file-1x; @file-2x; @width-1x; @height-1x) {
    background-image: url("@{file-1x}");

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
        background-image: url("@{file-2x}");
        background-size: @width-1x @height-1x;
    }
}

.divider(@pos: top; @weight: @divider-light; @color: @color-black) when (@pos = top) {
    border-top: @weight solid @color;
}

.divider(@pos: left; @weight: @divider-light; @color: @color-black) when (@pos = left) {
    border-left: @weight solid @color;
}

.divider(@pos: right; @weight: @divider-light; @color: @color-black) when (@pos = right) {
    border-right: @weight solid @color;
}

.divider(@pos: bottom; @weight: @divider-light; @color: @color-black) when (@pos = bottom) {
    border-bottom: @weight solid @color;
}

.divider-after(@weight: @divider-light; @color: @color-black; @padding-top: 0; @gutter: 0) {
    &:after {
        content: '';
        display: block;
        padding-top: @padding-top;
        .divider(bottom; @weight; @color);
        margin-left: @gutter;
        margin-right: @gutter;
    }
}

.divider-before(@weight: @divider-light; @color: @color-black; @padding-bottom: 0; @gutter: 0) {
    &:before {
        content: '';
        display: block;
        padding-bottom: @padding-bottom;
        .divider(top; @weight; @color);
        margin-left: @gutter;
        margin-right: @gutter;
    }
}

.link-super(@font-size: 20px) {
    font-weight: 700;
    font-size: @font-size;
    text-transform: uppercase;
}

.more-arrow(@weight: 2px; @color: @color-green; @direction: right) {
    &:after {
        display: inline-block;
        content: '';
        width: .5em;
        height: .5em;
        border-top: @weight solid @color;
        border-right: @weight solid @color;
        .rotate(45deg);
        display: inline-block;
        margin-left: .1em;
        margin-bottom: .2em;
        vertical-align: middle;
    }

    &:hover:after {
    }
}

.more-arrow(@weight: 3px; @color: @color-green; @direction: right) when (@direction = down) {
    &:after {
        .rotate(135deg);
        margin-left: .5em;
        margin-top: -0.5em;
        height: .66em;
    }
}

.plus-to-close-icon(@color: @color-black; @size: 12px; @thickness: 2px;) {
    &:before, &:after {
        content: "";
        position: absolute;
        top: 50%;
        display: block;
        width: @size;
        height: @thickness;
        background-color: @color;
        right: 10px;
        .transition(all 0.3s ease-out);
    }

    &:before {
        .rotate(0deg);
    }

    &:after {
        .rotate(90deg);
    }

    &.active {
        &:before {
            .rotate(180deg);
        }

        &:after {
            .rotate(180deg);
        }
    }
}

.btn-close-mixin(@button-size: 28px; @x-offset: 13px; @y-offset: 4px; @height: 20px; @width: 2px; @color: #fff; @background: @color-black-transparent; ) {
    cursor: pointer;
    background: @background;
    width: @button-size;
    height: @button-size;
    padding: 0;
    position: relative;
    display: inline-block;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: @y-offset;
        border-left: @width solid @color;
        height: @height;
    }

    &:before {
        .rotate(45deg);
        left: @x-offset;
    }

    &:after {
        .rotate(-45deg);
        right: @x-offset;
    }
}

.evo-gradient() {
    #gradient > .vertical(@color-white, @color-gray-light);
}

.link-super(@font-size: 20px) {
    font-weight: 700;
    font-size: @font-size;
    text-transform: uppercase;
}

.link-uber() {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}

.department-arrow(@color) {
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -14px;
        display: block;
        height: 28px;
        width: 28px;
        .rotate(45deg);
        border-right: solid 12px @color-gray-lighter;
        border-bottom: solid 12px @color-gray-light;
        z-index: @header-arrow-link-z-index;
        bottom: -25px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -9px;
        bottom: -9px;
        width: 18px;
        height: 18px;
        z-index: @header-arrow-link-after-z-index;
        .rotate(45deg);
        display: block;
        border-right: solid 10px @color;
        border-bottom: solid 10px @color;
    }
}

.separator-mixin(@number: 1) {
    width: 100%;
    clear: both;
    height: @separator-height;
    background-repeat: no-repeat;
    background-position: 50% top;
    border: 0;
    display: block;
    background-size: contain;

    @media (max-width: @screen-xs-max) {
        background-image: url(//static.evo.com/content/www/components/separators/separator-small.png);
        margin-bottom: 5px;
    }

    @media (min-width: @screen-sm-min) {
        background-image: url("//static.evo.com/content/www/components/separators/separator-@{number}.png");
        margin-bottom: @margin-base;
    }
}

.icon-mixin() {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
}

//only use this on no touch
.hovergrow() {
    .transition(all .25s ease-out .05s);
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover, &:active, &:focus {
        .scale(1.035,1.035);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,.15);
    }
}

.link-list-anchor(@padding: 10px 20px; @border: @border-gray-dark;) {
    display: block;
    padding: @padding;
    font-size: 16px;
    font-weight: @font-weight-bold;
    color: @color-gray-darkest;
    border-bottom: @border;
    white-space: nowrap;

    @media (min-width: @screen-md-min) {
        font-size: 24px;
        padding: 20px;
    }
}

.results-page-style() {
    .clearfix();
    background: #fff;
    padding: @page-full-margin-small @grid-gutter-width;
    border-top: 1px solid @color-aside-border;
    border-bottom: 1px solid @color-aside-border;

    @media (min-width: @screen-sm-min) {
        border: 1px solid @color-aside-border;
    }

    @media (min-width: @screen-md-min) {
        padding: @page-full-margin-medium;
    }
}

.full-width-container-mixin() {
    width: 100%;
    position: relative;
    .clearfix();
}

.container-full() {
    width: 100%;
    padding: 0 @gutter-mobile;
    margin: 0 auto;

    @media(min-width: @screen-sm) {
        padding: 0 @gutter * 3;
    }
}

.container-regular() {
    width: calc(~"1400px + @{gutter} * 6");
    max-width: 100%;
    padding: 0 @gutter-mobile;
    margin: 0 auto;

    @media(min-width: @screen-sm) {
        padding: 0 @gutter * 3;
    }
}

.container-md() {
    width: calc(~"1600px + @{gutter} * 6");
    max-width: 100%;
    padding: 0 @gutter-mobile;
    margin: 0 auto;

    @media(min-width: @screen-sm) {
        padding: 0 @gutter * 3;
    }
}

.container-sm() {
   width: calc(~"824px + @{gutter} * 6");
    max-width: 100%;
    padding: 0 @gutter-mobile;
    margin: 0 auto;

    @media(min-width: @screen-sm) {
        padding: 0 @gutter * 3;
    }
}

.stacked-section() {
    padding-top: @spacing-48;
    padding-bottom: @spacing-48;

    @media(min-width: @screen-sm) {
        padding-top: @gutter * 3;
        padding-bottom: @gutter * 3;
    }

    &:not([class^=bg-]) + .stacked-section:not([class^=bg-]) {
        padding-top: 0;
    }
}

.object-fit() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.stacked-section() {
    padding-top: @spacing-48;
    padding-bottom: @spacing-48;

    @media(min-width: @screen-sm) {
        padding-top: @gutter * 3;
        padding-bottom: @gutter * 3;
    }

    &:not([class^=bg-]) + .stacked-section:not([class^=bg-]) {
        padding-top: 0;
    }
}

.object-fit() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.evo-logo-inline() {
    text-transform: uppercase;
    font-weight: @font-weight-regular;

    &:before {
        font-family: "icomoon";
        content: @evo-logo-hex;
        font-size: 1.1em;
        position: relative;
        top: 0.12em;
    }
}

//    Previous Next Arrows for carousels, etc,
//    set in classes with previous-next-arrows.less and used as as mixin so we can drop it into
//    the Flexslider carousel banner.

@global-arrow-color: rgba(0,0,0,0.5);
@global-arrow-hover-color: @transparent-black;
@arrow-size-small: 32px;
@arrow-size-large: 42px;
@arrow-top-mutiplier: 0.34782609;
@arrow-side-muliplier: 0.2826087;

.global-arrow-mixin(@arrow-size-small: 30px, @arrow-size-large: 42px) {
    display: block;
    height: @arrow-size-small;
    width: @arrow-size-small;
    border-radius: 50%;
    right: @margin-small;
    left: auto;
    background-color: @global-arrow-color;
    position: absolute;
    z-index: @global-arrow-z-index;
    top: 50%;
    margin-top: (@arrow-size-small/2) * -1;
    cursor: pointer;
    .transition(all .3s ease-out);
    font-size: 0;

    @media (min-width: @screen-sm) {
        height: @arrow-size-large;
        width: @arrow-size-large;
        margin-top: (@arrow-size-large/2) * -1;
        right: @margin-medium;
    }

    &:hover {
        background-color: @global-arrow-hover-color;
    }

    &:after {
        @top-mutiplier: 0.34782609;
        content: "";
        display: block;
        position: absolute;
        top: @arrow-size-small * @arrow-top-mutiplier;
        width: @arrow-size-small*0.3;
        height: @arrow-size-small*0.3;
        border-top: 2px solid @color-white;
        border-right: 2px solid @color-white;
        left: @arrow-size-small * @arrow-side-muliplier;
        .rotate(45deg);

        @media (min-width: @screen-sm) {
            top: @arrow-size-large * @arrow-top-mutiplier;
            width: @arrow-size-large*0.3;
            height: @arrow-size-large*0.3;
            left: @arrow-size-large * @arrow-side-muliplier;
        }
    }

    &.arrow-hide {
        .translate3d(100px, 0, 0);
    }
}

.global-previous-arrow() {
    left: @margin-small;
    right: auto;
    -ms-transform: translate3d(0, 0, 0) rotate(180deg);
    -moz-transform: translate3d(0, 0, 0) rotate(180deg);
    -webkit-transform: translate3d(0, 0, 0) rotate(180deg);
    transform: translate3d(0, 0, 0) rotate(180deg);

    @media (min-width: @screen-sm) {
        left: @margin-medium;
    }

    &.arrow-hide {
        -ms-transform: translate3d(-100px, 0, 0) rotate(180deg);
        -moz-transform: translate3d(-100px, 0, 0) rotate(180deg);
        -webkit-transform: translate3d(-100px, 0, 0) rotate(180deg);
        transform: translate3d(-100px, 0, 0) rotate(180deg);
    }
}

.component-padding() {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: @screen-sm) {
        padding-top: @margin-large;
        padding-bottom: @margin-large;
    }

    @media (min-width: @screen-lg) {
        padding-top: @margin-super;
        padding-bottom: @margin-super;
    }
}

.component-margin() {
    margin-top: 30px;
    margin-bottom: 30px;

    @media (min-width: @screen-sm) {
        margin-top: @margin-large;
        margin-bottom: @margin-large;
    }
}

// TYPE 

.paragraph-regular() {
    .rem(14);
    line-height: 1.71;
    color: @color-gray-dark;

    @media (min-width: @screen-sm) {
        .rem(16);
        line-height: 1.75;
    }
}