@import 'imports.less';

//Layout Variables
//----------------------------------*/

//Divider weights
@divider-light: 1px;
@divider-heavy: 3px;

// Margins
@margin-xsmall: 5px;
@margin-small: 10px;
@margin-medium: 20px;
@margin-base: 25px;
@margin-large: 50px;
@margin-xlarge: 60px;
@margin-super: 80px;

@gutter-narrow: 10px;

@separator-height: 25px;

@list-padding: 18px;

@intro-text-max-width: 580px;

@text-block-width: 824px;

@border-light: 1px solid @color-gray-light;
@border-gray-dark: 1px solid @color-gray-darkest;
@border-dark: 1px solid @color-black;
@border-heavy: 3px solid @color-black;
@tile-border: 2px solid @color-gray-light;

@border-radius-lg: 24px;
@border-radius-xl: 48px;

//header Variables
//----------------------------------*/

@utilities-height: 36px;
@utilities-height-small: @utilities-height * 2; //stacked 2 high

@mobile-navigation-offset: 70%;
@tablet-navigation-offset: 240px;

@mobile-element-inset: 8px;

@navigation-height: 37px;

@logo-margin: 10px; //top and bottom at mobile

@logo-margin-bottom-small: 5px;
//@logo-margin-bottom-medium: 5px + @navigation-height; //room for navigation below at tablet
@logo-margin-bottom-medium: @logo-margin;
@logo-margin-bottom-large: @logo-margin;

@logo-height-xxs: 46px;
@logo-height-xs: 56px;
@logo-height-small: 80px;
@logo-height-medium: 75px;
@logo-height-large: 100px;

@logo-multiplier: 1.07;

@logo-width-xxs: @logo-height-xxs * @logo-multiplier;
@logo-width-xs: @logo-height-xs * @logo-multiplier;
@logo-width-small: @logo-height-small * @logo-multiplier;
@logo-width-medium: @logo-height-medium * @logo-multiplier;
@logo-width-large: @logo-height-large * @logo-multiplier;

@logo-margin-right: 30px;

@masthead-padding: 20px;
@masthead-mobile-vertical-padding: 7px;
@masthead-desktop-vertical-padding: 15px;
@masthead-border-bottom: 3px;
@masthead-search-height: 40px;

@navigation-breakpoint: 925px;
@navigation-breakpoint-large: 1600px;
@masthead-max-width: @navigation-breakpoint-large - (@masthead-padding * 2);
@masthead-height-medium: @logo-height-medium + @utilities-height + @masthead-desktop-vertical-padding + @navigation-height + 5;
@masthead-height-large: @logo-height-large + @utilities-height + @masthead-desktop-vertical-padding + 12;

//navigation variables
@sub-nav-height: 475px;

//Global utility navigation variables
@utility-navigation-padding: 1.1em;

//hexadecimal symbol codes
@open-quote-stylized: "\201C";

//evotrip page variables
@area-section-map-mobile-height: 250px;
@area-section-map-desktop-height: 500px;

//Mobile Navigation Variables
@navigation-border-width: 2px;
@navigation-border: @navigation-border-width solid @gray-light;

//height for department buttons and search forms
@department-height: 35px;

@evo-container-min: (925px + @grid-gutter-width);

//custom Colors
@color-blue: rgb(0,153,255); // 0,153,255
@color-blue-light: rgb(157,210,246); // 157,210,246
@color-blue-dark: #007ACB;
@color-green: rgb(64,174,50);
@color-green-dark: #0f8800;
@color-green-darker: #0D7100;
@color-green-light: rgb(149,204,63);
@color-green-super-light: rgb(232, 245, 230);
@color-green-yellow: rgba(147,200,20,.75);
@color-dark-teal: #1B4C58;
@color-darker-teal: #20373D;
@color-tomato: #D25E48;
@color-tomato-wcag: #BD5441;
@color-sunny: #EAC36B;
@color-sage: #698F5B;
@color-sage-wcag: #5D7E50;
@color-sage-light: rgba(105, 143, 91, 0.1);
@color-sky-blue: #5C91B5;
@color-sky-blue-wcag: #4E7B9A;
@color-light-tan: #F4EAC5;
@color-black: #2e2e2e;
@color-gray-darkest: #424242; // lighten(@color-black, 10%);
@color-gray-darker: #585858; // lighten(@color-black, 20%);
@color-gray-dark: #6c6c6c; // lighten(@color-black, 30%);
@color-gray: #969696; // lighten(@color-black, 50%);
@color-gray-light: #d5d5d5; // lighten(@color-black, 80%);
@color-gray-lighter: #f4f4f4; // lighten(@color-black, 95%);

@color-red: rgb(204, 0, 0);
@color-red-light: lighten(@color-red, 40%);
@color-purple: rgb(200, 42, 251); //c82afb
@color-orange: #f99400; //#ff6300
@color-white: rgb(255, 255, 255);
@color-black-transparent: rgba(0,0,0,.85);
@color-discount: @color-red;
@color-anchor-nav-active: rgba(255,255,255, 0.25);
@color-anchor-nav-hover: rgba(255,255,255, 0.15);
@color-transparent-green: #40ae320d;

@color-input-border: #B8B8B8;

//ZR type variables
@font-family-base: 'Century Gothic','Avenir','Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-loaded: 'Mont', 'Century Gothic','Avenir','Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-condensed-loaded: 'Amsi Pro Narrow', 'Arial Black', 'Arial Bold', 'Gadget', sans-serif;

@font-weight-regular: 400;
@font-weight-semibold: 600;
@font-weight-bold: 700;
@letter-spacing-base: .0625em;

@font-size-20: 20px;

//** Global textual link color.
@link-color: @color-green-dark;
//** Link hover color set via `darken()` function.
@link-hover-color: darken(@link-color, 15%);

//all things evo colors
@color-events: rgb(217,202,20);

//-------Accordion Component Variables -------------
@accordion-padding: 15px;

//-------Scaffolding Variables -------------
@page-full-margin-small: 15px;
@page-full-margin-medium: 30px;
@page-full-margin-large: 50px;

//-------Sidebar Component Variables -------------
@sidebar-margin-small: 15px;
@sidebar-margin-medium: 30px;
//-------Product Variables - shared with PDP and results -------------------
//-------Results Variables -------------------
@results-sidebar-width-small: 28%;
@results-sidebar-width: 24%;
@results-sidebar-width-large: 320px;
//-------PDP Variables -------------------
@pdp-breakpoint: 640px;
@pdp-breakpoint-max: @pdp-breakpoint - 1;
@pdp-sidebar-width-small: 38%;
@pdp-sidebar-width: 33%;
@pdp-sidebar-width-large: 450px;
@pdp-column-max: 680px;
@pdp-margin-small: 15px;
@pdp-margin-medium: 30px;
@pdp-hero-sidebar-margin: 40px;
@pdp-hero-width: 680px;
@pdp-nav-breakpoint: 925px;

//PDP TEST VARIABLES - remove "-test" section and remove duplicates above if/when ready
@pdp-breakpoint-test: 780px;
@pdp-breakpoint-max-test: @pdp-breakpoint-test - 1;
@pdp-sidebar-width-large-test: 420px;
@pdp-margin-medium-test: 20px;

//PDP recently viewed variables
@recent-thumb-width-small: 130px;
@recent-thumb-width-medium: 160px;
@recent-thumb-width-large: 180px;
@recent-thumb-height-small: 220px;
@recent-thumb-height-medium: 250px;
@recent-thumb-image-width-small: 70px;
@recent-thumb-image-width-medium: 100px;
@breadcrumb-recent-width-small: 140px;
@breadcrumb-recent-width-med: 385px;

//-----Product Thumb ---------------------------------------------
//-----------Blog colors used in callouts, highlights, category names, etc-----------------
//TODO convert to RGB
@color-blog-default: rgb(153, 153, 153);
@color-blog-art: @color-blog-default;
@color-blog-cause: rgb(231,120,212);
@color-blog-bike: rgb(45,129,190);
@color-blog-comps: rgb(244,67,67);
@color-blog-events: @color-events;
@color-blog-featured: rgb(124, 127, 193);
@color-blog-new-arrivals: rgb(255, 101, 190);
@color-blog-news: rgb(251, 233, 0);
@color-blog-parties: rgb(207, 251, 0);
@color-blog-portland: rgb(226, 82, 47);
@color-blog-seattle: rgb(226, 153, 67);
@color-blog-skate: rgb(156, 216, 24);
@color-blog-ski: rgb(88, 189, 211);
@color-blog-snowboards: rgb(185, 189, 228);
//used for sidebars and cart background {}
@color-aside: @gray-lighter;
@color-aside-border: @gray-light;
//Transitions
//Default transition duration
@transition-duration: .25s;
//Default transition function
@transition-function: ease-out;
//Base Transition:
@transition-base: all .25s ease-out;
@transition-bounce-easing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
@transition-bounce: .35s @transition-bounce-easing;
//blog related variables
@blog-img-margin: 20px;
@blog-large-nav-width: 260px;

//Component-title
@component-title-lg: 32px;
@component-title-sm: 24px;

@component-margin-small: 15px;
@component-margin-base: 30px;
@component-margin-large: 60px;

//hex content values
@evo-logo-hex: "\e601";

//Spacing Variables
//----------------------------------*/
@spacing-8: 8px;
@spacing-12: 12px;
@spacing-16: 16px;
@spacing-20: 20px;
@spacing-24: 24px;
@spacing-32: 32px;
@spacing-40: 40px;
@spacing-48: 48px;
@spacing-56: 56px;
@spacing-60: 60px;
@spacing-64: 64px;
@spacing-72: 72px;
@spacing-80: 80px;
@spacing-112: 112px;
@spacing-120: 120px;
@spacing-200: 200px;

@gutter: 2.08vw; // 40px
@gutter-mobile: 32px;
@section-spacing: @gutter * 3;