﻿@import '../framework/imports.less';

.countdown-container {
    display: none;
    padding-top: 10px;
    padding-right: 10px;
    border-top: @border-light;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    // styles for checkout specific countdown display
    .checkout-cart-container-add-on & {
        padding: 10px 30px;
        background: @color-green;
        color: @color-white;
        margin-bottom: 0;
        border-top: 0;
        margin-top: 0;
        font-weight: @font-weight-semibold;

        a {
            color: @color-white;
        }

        .countdown-section {
            color: @color-white;

            &:after {
                background-color: @color-white;
            }
        }
    }

    &.loaded {
        display: flex;
    }

    .countdown-section {
        color: #2e2e2e;
        font-size: 13px;
        line-height: 1.2;
        padding: 0 7px 0 1px;
        position: relative;
        text-align: center;
        width: auto;

        .countdown-number {
            font-size: 1.2em;
            font-weight: bold;
            width: 100%;
        }

        &:after {
            background-color: gray;
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 0;
            width: 1px;
        }

        &:last-child {
            text-align: left;
            padding-right: 0;

            &:after {
                display: none;
            }
        }
    }
}