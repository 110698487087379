@import '../framework/imports.less';

// Callouts

//triplets
.callout-wrapper {
    .component-margin;
}

.callout {
    a {
        display: block;
    }

    img {
        width: 100%; // we hopefully wont need this if images are big enough
    }
}

.callout-stacked {
    @media (max-width: @screen-xs-max) {
        .row {
            margin: 0;
        }
    }

    .callouts {
        @media (min-width: @screen-xs-min) {
            .flex-display();
            .justify-content(@justify: center);
        }
    }

    .callout {
        margin-bottom: (@grid-gutter-width/2);

        a {
            margin: auto;
        }

        @media (min-width:@screen-xs-min) {
            padding: 0 (@grid-gutter-width/2);
            margin-bottom: 0;

            a {
                width: 100%;
            }
        }

        @media (min-width: @screen-desktop) {
            &:nth-child(3n+1) {
                clear: both;
            }
        }
    }
}

.touch .callout-scroller .callout-container {
    overflow-x: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
}

.callout-scroller {
    .callout-container {
        overflow-x: auto;
    }

    .callouts {
        min-width: 738px; // supposes full width of .container
        .flex-display();

        @media (min-width: @screen-lg) {
            .justify-content(center);
        }
    }

    .callout {
        display: inline-block; // browser support
        width: 33.3333%; // browser support
        .flex(0 0 80vw);
        padding: 0 @grid-gutter-width/2;
        margin-bottom: @margin-base;

        @media (min-width: @screen-xs) {
            .flex-basis(33.3333%);
        }

        @media (min-width: @screen-sm) {
            .flex-basis(auto);
        }
    }
}
//size guide callouts
.size-guide-callout {
    display: inline-block;
    color: #000;
    background: @color-gray-lighter;
    border-radius: 2px;
    padding: 4px 20px 4px 23px;
    position: relative;
    font-weight: bold;
    font-size: 13px;
    line-height: 1.2em;

    @media (min-width: @screen-sm-min) {
        padding: 8px 30px 8px 42px;
    }

    @media (min-width: @screen-md-min) {
        font-size: 16px;
        padding-left: 50px;
        padding-right: 36px;
    }

    small {
        text-transform: uppercase;
        font-size: .82em;
    }

    &:hover,
    &:active,
    &:focus {
        color: #000;
        text-decoration: none;
    }
    /*&:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #fff;
    }*/
    .measure-icon {
        width: 18px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -15px;

        @media (min-width: @screen-sm-min) {
            width: 36px;
            margin-top: -27px;
        }

        @media (min-width: @screen-md-min) {
            width: 49px;
            left: 0;
            margin-top: -38px;
        }
    }

    .size-guide-arrow {
        font-size: 11px;
        position: absolute;
        line-height: 1em;
        top: 50%;
        margin-top: -6px;
        right: 7px;

        @media (min-width: @screen-md-min) {
            margin-top: -8px;
            font-size: 14px;
        }

        @media (min-width: @screen-md-min) {
            font-size: 18px;
            margin-top: -10px;
        }
    }
}
//remove transitions for touch
.no-touch {
    .size-guide-arrow {
        .transition(@transition-base);
    }

    .size-guide-callout:hover .side-guide-arrow {
        right: 3px;
    }
}

.size-guide-text {
    display: block;
    text-align: left;
}

//callout fade scale
.callout-fade {
    position: relative;
    overflow: hidden;
}

.callout-fade .callout-caption {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 10px;
    .flex-display(flex);
    .flex-direction(column);
    .justify-content(center);
    right: 0;
    background: rgba(0,0,0,.3);
    .transition(background @transition-duration @transition-function .1s);

    @media (max-width: @screen-sm-max) {
        display: none;
    }

    .no-touch & {
        background: rgba(0,0,0,0);
    }
}

.no-touch .callout-fade .callout-caption-inner {
    .opacity(0);
    .scale(1.1);
    .transition(@transition-base .1s);
}

.callout-fade:hover {
    .callout-caption {
        background: rgba(0,0,0,.5);
    }

    .callout-caption-inner {
        .opacity(1);
        .scale(1);
    }
}

//instagram callouts
.callout-instagram {
    padding-top: @grid-gutter-width/2;
    padding-bottom: @grid-gutter-width/2;
}

.callout-instagram-link {
    background-size: cover;
    display: block;
    padding-bottom: 100%;
    position: relative;
    font-size: 14px;

    @media (min-width: @screen-md) {
        font-size: 16px;
    }
}

.callout-instagram-caption span {
    display: block;
}

.callout-instagram-date {
    font-weight: bold;
}

.instagram-container + .text-center {
    margin-top: (@grid-gutter-width/2) * -1;
    clear: both;
}

//flexible image callouts
.flexible-callout-container {
    .flex-display;
    .flex-direction(column);
    padding: @component-margin-base 0;

    @media (min-width: @screen-sm) {
        .flex-direction(row);
        margin: 0 ((@grid-gutter-width/2) * -1);
        padding: @component-margin-large 0;
    }
}

.flexible-callout {
    background: black;
    .flex-grow(1);
    width: 100%;
    position: relative;
    color: @color-white;
    overflow: hidden;
    margin: @margin-small 0;
    .translate(0,0);
    .transition(all 500ms ease-out);

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: @color-white;
    }

    @media (min-width: @screen-sm) {
        width: (@container-desktop / 2) - @grid-gutter-width;
        margin: 0 @margin-small;
    }
}

.flexible-callout-image {
    .transition(all 500ms ease-out);
    .opacity(.9);
}

.no-touch .flexible-callout:hover .flexible-callout-image {
    .opacity(.7);
    .scale(1.05,1.05);
}

.flexible-callout-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .flex-display;
    .flex-direction(column);
    .justify-content(center);
    .align-items(center);

    .h3 {
        margin-top: 0;
    }

    .btn {
        border: 2px solid @color-white;
        color: @color-white;

        &:hover {
            border: 2px solid @color-white;
            color: @color-white;
            background: transparent;
        }
    }
}