﻿@import '../framework/imports.less';

@responsive-banner-header-max-font-size: 55px;
@responsive-banner-hit-area-z-index: 1;
@responsive-banner-active-hit-area-z-index: @responsive-banner-hit-area-z-index + 1;

.responsive-banner {
    position: relative;
}

.responsive-banner-image {
    display: block;
}

.responsive-banner-image-wrapper {
    img {
        min-width: 100%;
    }
}

.responsive-banner-header {
    font-size: @font-size-large;

    @media (min-width: @screen-xs) {
        font-size: 4.2vw;
    }

    @media (min-width: @screen-sm) {
        font-size: 3.8vw;
    }

    @media (min-width: @screen-xl-min) {
        font-size: @responsive-banner-header-max-font-size;
    }
}

.responsive-banner-content {
    .flex-display();
    color: @color-white;
    .flex-direction(column);
    .justify-content(center);
    .align-items(center);
    padding: 10%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: @screen-xs) {
        padding: 5%;
    }

    @media (min-width: @screen-md) {
        padding: 4%;
    }

    .responsive-banner-buttons {
        position: relative;
        font-size: 0;
        z-index: @hero-banner-link-z-index;
    }

    .btn {
        margin: @margin-xsmall;
        font-weight: normal;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
        white-space: nowrap;

        @media (min-width: @screen-sm) {
            font-size: 1.4vw;
        }
    }

    @media (min-width: @screen-sm) {
        background: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.left {
        right: auto;
        text-align: left;
        .align-items(flex-start);
    }

    &.right {
        left: auto;
        text-align: right;
        .align-items(flex-end);
    }

    &.center {
        right: auto;
        left: 50%;
        .translate(-50%, 0);
    }

    &.top {
        bottom: auto;
        .justify-content(flex-start);
    }

    &.bottom {
        top: auto;
        .justify-content(flex-end);
    }

    &.middle {
        bottom: auto;
        top: 50%;
        .translate(0, -50%);

        &.center {
            .translate(-50%, -50%);
        }
    }

    &.stacked-buttons {
        .responsive-banner-buttons .btn {
            display: block;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        margin-top: 0;
        margin-bottom: 15px;
        line-height: 1.2em;
    }
}

.responsive-banner-content-below {
    @media (max-width: @screen-xs-max) {
        background: @color-black;
        position: relative;
        text-align: center !important;
        .flex-display(block);
        width: 100% !important;

        .responsive-banner-buttons {
            width: 100%;
        }

        .responsive-banner-buttons .btn {
            width: 80%;
            display: block;
            margin: 10px auto;
            border: 1px solid #fff;
        }
    }
}

.responsive-banner-content-desktop-only {
    display: none;

    @media (min-width: @screen-sm) {
        .flex-display;
    }
}

.responsive-banner-subtitle {
    margin: 0 0 25px 0;
    font-size: 16px;
}

.responsive-banner-hit-area-container {
    position: absolute;
    z-index: @responsive-banner-hit-area-z-index;

    &.active {
        z-index: @responsive-banner-active-hit-area-z-index;
    }
}

.responsive-banner-hit-area {
    width: 24px;
    height: 24px;
    .plus-to-close-icon(@color-black; 10px);
    .transition(all .35s @transition-bounce-easing);

    &:before,
    &:after {
        left: 50%;
        margin-left: -5px;
        opacity: 0;
        transition: all 0.3s ease-out;
        z-index: 1;

        @media (max-width: @screen-xs-max) {
            .opacity(1);
        }

        .responsive-banner-image-wrapper:hover &,
        .active & {
            .opacity(1);
        }
    }

    .hit-area-circle {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        background: @color-white;
        border-radius: 100%;
        cursor: pointer;
        transform: translate(-50%, -50%) scale(.7);
        .opacity(0.7);
        .transition(all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275));
        .box-shadow(0 0 4px rgba(0,0,0,1));

        @media (max-width: @screen-xs-max) {
            transform: translate(-50%, -50%) scale(1.1);
        }

        .responsive-banner-image-wrapper:hover &,
        .active & {
            .opacity(1);
            transform: translate(-50%, -50%) scale(1.1);
        }
    }

    .active & {
        &:before,
        &:after {
            .rotate(180deg);
        }
    }
}

.repsonsive-banner-tool-tip {
    background: @color-white;
    bottom: 36px;
    color: @color-black;
    font-size: @font-size-small;
    left: 50%;
    padding: 15px;
    position: absolute;
    text-align: center;
    text-transform: none;
    width: 220px;
    cursor: default;
    .opacity(0);
    .box-shadow(0px 0px 3px rgba(0,0,0, 0.5));
    transition: opacity .3s ease-out, transform .3s cubic-bezier(.17,.65,.66,1.52);
    .translate(-50%;20px);
    pointer-events: none;
    z-index: -1;

    .responsive-banner-hit-area-container.active & {
        .opacity(1);
        .translate(-50%;0);
        pointer-events: auto;
        z-index: 1;
    }

    .tooltip-below & {
        top: calc(~"100% + 10px");
        bottom: auto;

        &:after {
            top: -20px;
            border: 10px solid transparent;
            border-bottom: 10px solid @color-white;
        }
    }

    @media (max-width: @screen-xs-max) {
        position: fixed;
        top: auto !important;
        bottom: 0 !important;
        width: 100%;
        height: 150px;
        padding-left: 135px;
        padding-right: @pdp-margin-medium;
        text-align: left;

        &:after {
            display: none;
        }
    }

    &:after {
        content: '';
        border: 10px solid transparent;
        border-top: 10px solid @color-white;
        left: 100px;
        top: 100%;
        position: absolute;
    }
}

.tool-tip-button {
    margin-top: 10px;
}

.responsive-banner-tool-tip-image {
    @media (max-width: @screen-xs-max) {
        min-width: 0 !important;
        width: 100px;
        position: absolute;
        left: @pdp-margin-small;
        top: 50%;
        margin-top: -50px;
    }
}

.responsive-banner-tool-tip-text {
    font-weight: @font-weight-semibold;
    margin-bottom: .5em;
    display: block;
}

.responsive-banner-tool-tip-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: @margin-small;
    font-size: @font-size-base;
    cursor: pointer;

    @media (min-width: @screen-sm) {
        display: none;
    }
}

// To hide hyperlinks from slick carousel before javascript is loaded
.slick-carousel .responsive-banner-image-wrapper {
    display: none;
}