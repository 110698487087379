﻿@import '../framework/imports.less';

.link-list {
    list-style-type: none;
    padding: 0;
    margin: 25px 0;
    width: 100%;
    float: left;
    text-align: left;
    font-weight: @font-weight-regular;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    @media (min-width: @screen-md) {
        margin: 50px 0;
    }
}

.link-list-item {
    .make-xs-column(12);

    @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
        width: 50%;
    }

    .make-sm-column(4);
    .make-lg-column(3);

    @media (max-width: @screen-xs) {
        width: 100%;
    }

    &:last-of-type a {
        border-bottom: @border-gray-dark;
    }

    @media (min-width: @screen-xs-min) {
        &:nth-last-of-type(2) a {
            border-bottom: @border-gray-dark;
        }
    }

    @media (min-width: @screen-sm) {
        &:nth-last-of-type(3) a {
            border-bottom: @border-gray-dark;
        }
    }

    @media (min-width: @screen-lg) {
        &:nth-last-of-type(4) a {
            border-bottom: @border-gray-dark;
        }
    }
}

.link-list-item a {
    .link-list-anchor();
}

.link-list-item.js-brand {
    a.link-list-item-brand-view-link {
        font-size: 20px;

        @media(max-width: @screen-desktop) {
            font-size: 16px;
        }
    }
}