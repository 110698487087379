﻿@import '../framework/imports.less';

// Outlet Landing Page Button List
.button-list {
    display: block;
    clear: both;

    @media (min-width: @screen-sm-min) {
        text-align: center; // browser support
        .flex-display();
        .justify-content(center);
    }

    a {
        width: 100%;
        white-space: normal;
    }
}

.button-list-item {
    max-width: 400px;
    padding: 0 (@grid-gutter-width/2);
    margin: 0 auto (@margin-base - 5px); // minus margin-top of hr 5px
    @media (min-width: @screen-sm-min) {
        display: inline-block; // browser support
        .flex(0 1 400px);
        margin-left: 0;
        margin-right: 0;
    }

    @media (min-width: @screen-md) {
        margin-bottom: (@margin-large - 5px); // minus margin-top of hr 5px
    }
}

// Flex button list
.flex-button-list {
    .component-margin();
    padding: 0;
    text-align: center;

    @media (min-width: @screen-sm) {
        .flex-wrap(wrap);
        margin-left: (@grid-gutter-width/-2);
        margin-right: (@grid-gutter-width/-2);
    }

    .flex-button-list-item {
        display: inline-block; // browser support
        max-width: 400px;
        min-width: 75%;
        padding: 0 (@grid-gutter-width/2);
        margin: @margin-small/2 auto;

        @media (min-width: @screen-sm) {
            min-width: 0;
            margin: @margin-small/2 0;
            .flex(0 1 50%);
        }
    }

    .flex-button {
        white-space: nowrap;
    }
}

.flex-button-list.three-up .flex-button-list-item {
    @media (min-width: @screen-md) {
        .flex-basis(33%);
    }
}