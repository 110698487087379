﻿@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/navs.less';

.nav {
    > li {
        > a:hover, > a:focus {
            background-color: @color-anchor-nav-hover;
        }
    }
}