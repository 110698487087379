@import '../framework/imports.less';

//---------------------------------------------------------
// Blog common  could be used many places in site
// also includes styling for blog category pages
//---------------------------------------------------------

.blog-loading {
    .loading();
}

/* Thumbnails
--------------------------------------------*/
.post-thumb-row {
    margin-left: -3px;
    margin-right: -3px;
}

.post-thumb {
    .opacity(1);
    .translate(0;0);
    .transition(all .5s @transition-function);
    float: left;
    padding: @padding-base-vertical / 2;
    width: 100%;

    &.faded {
        .opacity( 0);
        transform: translate(0, 20px);
    }

    @media (min-width: 580px) {
        width: 50%;

        &:nth-child(2n + 1) {
            clear: both;
        }
    }

    @media (min-width: @screen-md) {
        &:nth-child(n+3) {
            width: 33.33333333%;

            .post-thumb-title {
                font-size: @font-size-medium;
            }
        }

        &:nth-child(2n + 1) {
            clear: none;
        }

        &:nth-child(3n + 3) {
            clear: both;
        }
    }

    .wp-post-image {
        .opacity(.8);
        .transition(all 0.4s ease-out);
        height: auto !important;
        max-width: 100%;
        position: relative;
        z-index: 10;
    }
}

.no-touch .post-thumb:hover .wp-post-image {
    .opacity(.5);
    .scale(1.05);
}

.post-thumb-title {
    color: @color-white;
    font-size: @font-size-medium;
    font-weight: @font-weight-bold;
    line-height: @headings-line-height;
    margin: 0 0 .25em 0;

    @media (min-width: 1024px) {
        font-size: @font-size-h3;
    }
}

.post-thumb-date {
    font-size: @font-size-base;
    margin-bottom: .5em;
}

.post-thumb-inner {
    background: @color-black;
    color: @color-white;
    overflow: hidden;
    position: relative;
}

.post-thumb-text {
    overflow: hidden;
    padding: @padding-small-horizontal;
    position: absolute;
    z-index: 15;
}

.post-thumb-excerpt {
    .opacity(0);
    .scale(1.1);
    .transition(all 0.4s ease-out);
    border-top: 1px solid @color-white;
    padding-top: 1em;
}

.no-touch .post-thumb:hover .post-thumb-excerpt {
    .opacity(1);
    .scale(1);
}