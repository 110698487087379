﻿@import '../framework/imports.less';

.divider-list {
    .clear-list-formatting();
    border-top: @border-light;
    font-weight: @font-weight-regular;

    li {
        border-bottom: @border-light;
    }

    a {
        display: block;
        padding-top: .5em;
        padding-bottom: .5em;
        color: #000;

        &:hover, &:active, &:focus {
            color: #000;
            text-decoration: none;
        }
    }
}