@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/forms.less';
//
// Forms
// --------------------------------------------------

.form-group label {
    font-weight: @font-weight-regular;
}

.form-control {
    border: 1px solid @input-border;
    border-radius: 0 !important;
    .box-shadow(none);
    .transition(border-color .3s ease-in);

    &:focus {
        .box-shadow(none);
    }

    label {
        text-transform: uppercase;
        font-weight: @font-weight-regular;
        .rem(12);
        line-height: 1em;
    }
}

select {
    &.input-sm {
        line-height: @line-height-small;
    }

    &.input-lg {
        line-height: @line-height-large;
    }
}

label {
    font-weight: @font-weight-regular;
}

label.required:after {
    content: '*';
    color: @color-red;
}

.label-help {
    background: @color-black;
    padding: .25em .6em;
    border-radius: 1.7em;
    color: @color-white;
    font-size: .84em;
    vertical-align: text-top;

    &:hover {
        color: @color-white;
        text-decoration: none;
        background-color: darken(@brand-primary, 10%);
    }
}

// .input-sm {
//   padding-top: 2px;
//   padding-bottom: 2px;
//   line-height: 1.42em;
// }

input[type="number"]:not(.hide-number) {
    padding-right: (@padding-base-horizontal - 10);
}

input[type="number"]:not(.hide-number).input-sm {
    padding-right: (@padding-small-horizontal - 10);
}

input[type="number"]:not(.hide-number).input-lg {
    padding-right: (@padding-large-horizontal - 10);
}

input[type="number"].hide-number {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.has-success {
    .form-control-validation(inherit; @gray-lighter; @color-white);

    .form-control:focus {
        border-color: @input-border-focus;
    }
}

.form-group.last {
    margin-bottom: 0;
}

.radio,
.checkbox {
    label {
        padding-left: 25px;
    }
}

.fancy-radio.radio-inline,
.fancy-checkbox.checkbox-inline {
    padding-left: 0;
    margin-right: 10px;
}

.fancy-radio,
.fancy-checkbox {
    margin-bottom: 15px;
    margin-top: 0;

    input[type="checkbox"],
    input[type="radio"] {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
    }

    label {
        position: relative;
        display: inline-block;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0 !important;
            // margin-top: -9px !important;
            width: 20px;
            height: 20px;
            background-color: @color-white;
            border: 2px solid @color-black;
            .transition(@transition-bounce);
        }
    }
}

.fancy-radio {
    label {
        &:before,
        &:after {
            border-radius: 50%;
            content: '';
            position: absolute;
            left: 0;
            top: 0 !important;
        }
    }

    label:after {
        width: 10px;
        height: 10px;
        background-color: @color-black;
        transform: translate(50%, 50%) scale(0);
        .transition(@transition-bounce);
    }

    input:checked + label:after {
        transform: translate(50%, 50%) scale(1);
    }
}

.fancy-checkbox {
    label {
        &:after {
            content: @icon-checkmark4;
            font-family: 'icomoon';
            // font-size: 12px;
            color: #fff;
            .opacity(0);
            left: 4px;
            top: 2px;
            font-size: 12px;
            position: absolute;
            .transition(@transition-bounce);
            .scale(0);
        }
    }

    input:checked + label,
    input:checked + input + label {
        &:before {
            background: @color-black;
        }

        &:after {
            .opacity(1);
            .scale(1);
        }
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

input[type=text],
input[type=number],
input[type=email],
input[type=tel],
input[type=password],
select,
textarea {
    -webkit-appearance: none;
}

/*
  add this class to the parent div of a radio/checkbox label combo
  and it will create the styles that show a box
  around the label
*/
.label-indicator, .label-indicator.radio, .label-indicator.checkbox {
    position: relative;

    input[type=checkbox], input[type=radio] {
        position: absolute;
        left: 5px;
        top: 1em;
        margin: 0;

        &:checked + label {
            background: @color-green-super-light;
        }
    }

    label {
        width: auto;
        display: block;
        padding-left: 25px;
        padding: 10px 10px 10px 25px;
        font-weight: normal;
        margin-bottom: 0;
        cursor: pointer;
        .transition(background .3s ease-in);

        * {
            vertical-align: bottom;
            display: inline-block;
        }
    }
}

.webkit select {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNiAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTYgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJpY29tb29uLWlnbm9yZSI+DQo8L2c+DQo8cG9seWdvbiBwb2ludHM9IjExLjgsMTAgOC4zLDE0LjYgNC44LDEwICIvPg0KPHBvbHlnb24gcG9pbnRzPSI0LjgsNiA4LjMsMS40IDExLjgsNiAiLz4NCjwvc3ZnPg0K');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 20px auto;

    &.input-sm {
        background-size: 15px auto;
    }
}

.form-button {
    @media (max-width: @screen-phone) {
        width: 100%;
    }
}

.microcopy-center,
.microcopy {
    font-size: @font-size-small;
    margin-top: 5px;
    display: inline-block;
    font-weight: @font-weight-regular;
}

.microcopy-center {
    text-align: center;
}

.microcopy a,
a.microcopy {
    text-decoration: underline;
}

.hidden-input-link {
    display: inline-block;
    line-height: @input-height-base;
}

.hide-radio {
    display: none;
}

#payment-radios {
    white-space: nowrap;

    .display-affirm {
        display: inline;
    }

    .display-paypal {
        display: inline;
    }
}

@media screen and (max-device-width : @navigation-breakpoint) {
    #payment-radios {
        .display-affirm {
            margin-left: 0;
            padding-top: 5px;
            display: block;
        }

        .display-paypal {
            margin-left: 0 !important;
            padding-top: 20px !important;
            display: block !important;
        }

        .second-selection {
            padding-top: 20px;
        }
    }
}