﻿@import '../framework/imports.less';

.top-anchor {
    color: #fff;
    text-align: center;
    background-color: fadeout(@gray, 35%);
    font-size: 1.2em;

    @media (min-width: @screen-sm-min) {
        font-size: 1.6em;
    }

    height: 1.8em;
    width: 1.8em;
    border-radius: 1em;
    position: fixed;
    .opacity(0);
    bottom: @margin-base;
    right: 10px;
    .transition(@transition-base);
    cursor: pointer;
    z-index: @top-anchor-z-index;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    &.active {
        .opacity(1);
    }

    &:hover {
        background-color: fadeout(@gray, 15%);
    }

    &:before {
        line-height: 1.65em;
    }
}