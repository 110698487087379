﻿@icomoon-font-family: "evo-icomoon";
@icomoon-font-path: "fonts";

@icon-membership: "\e909";
@icon-tiktok: "\e902";
@icon-plane: "\e900";
@icon-pen6: "\e927";
@icon-file-plus2: "\e9a2";
@icon-clock2: "\ea4e";
@icon-floppy-disk2: "\ea89";
@icon-bubble9: "\ead5";
@icon-user32: "\33";
@icon-user4: "\eb06";
@icon-cog: "\77";
@icon-link: "\ec96";
@icon-checkmark4: "\63";
@icon-pause2: "\ed83";
@icon-filter: "\ee88";
@icon-share: "\eec4";
@icon-linkedin2: "\ef25";
@icon-price-tag2: "\e9ee";
@icon-phone2: "\ea1d";
@icon-envelop3: "\ea32";
@icon-user3: "\75";
@icon-cross2: "\ed6a";
@icon-evo_holiday_gifting_shop_icon: "\e901";
@icon-Save-invoice: "\31";
@icon-selector: "\32";
@icon-trash: "\74";
@icon-Sales: "\64";
@icon-Search-customer: "\73";
@icon-New-customer: "\6e";
@icon-New-Invoice: "\69";
@icon-Barcode: "\62";
@icon-signout: "\6f";
@icon-r0: "\e603";
@icon-r-5: "\e619";
@icon-r1-5: "\e61a";
@icon-r1: "\e61b";
@icon-r2-5: "\e61c";
@icon-r2: "\e61d";
@icon-r3-5: "\e61e";
@icon-r3: "\e61f";
@icon-r4-5: "\e620";
@icon-r4: "\e621";
@icon-r5: "\e622";
@icon-evo-checkmark: "\e624";
@icon-clock: "\e623";
@icon-clock2: "\e94f";
@icon-plus2: "\70";
@icon-minus: "\6d";
@icon-arrow-left: "\e608";
@icon-arrow-left-medium: "\e60a";
@icon-arrow-left-large: "\e60b";
@icon-arrow-right: "\e60d";
@icon-arrow-right-medium: "\e60e";
@icon-arrow-right-large: "\e610";
@icon-arrow-up-small: "\e611";
@icon-arrow-up-medium: "\71";
@icon-arrow-up-large: "\38";
@icon-arrow-down: "\e614";
@icon-arrow-down-medium: "\61";
@icon-arrow-down-large: "\39";
@icon-search: "\6c";
@icon-zoom-in: "\e617";
@icon-zoom-out: "\e618";
@icon-google-plus2: "\e605";
@icon-facebook: "\e606";
@icon-google-plus: "\e609";
@icon-pinterest: "\e60c";
@icon-cart: "\e600";
@icon-evo-text: "\e601";
@icon-ruler: "\e60f";
@icon-notification2: "\ed4f";
@icon-question4: "\ed53";
@icon-play: "\ed82";
@icon-facebook2: "\ea8d";
@icon-instagram: "\ea90";
@icon-twitter: "\ea91";
@icon-youtube3: "\ea99";
@icon-plus: "\e607";
@icon-minus2: "\e626";
@icon-ico_user: "\e625";
@icon-pencil: "\e905";
@icon-location: "\e947";
@icon-link2: "\e9cb";
@icon-share2: "\ea7d";
@icon-location5: "\ea3c";
@icon-members-earn: "\e92c";
@icon-returns: "\e92a";
@icon-lowest-price: "\e921";
@icon-free-shipping: "\e917";
@icon-edit: "\e930";
@icon-remove: "\e91e";
@icon-call-or-text: "\e92f";
@icon-start-chat: "\e929";
@icon-email: "\e92d";
@icon-help-center: "\e923";
@icon-warning: "\e951";
@icon-info: "\e94c";
@icon-puzzle: "\e956";
@icon-location-pin: "\e957";
@icon-printer: "\e90b";
@icon-save-for-later: "\e90c";
@icon-give-with-us: "\e90d";
@icon-rent-with-us: "\e90e";
@icon-stay-with-us: "\e90f";
@icon-travel-with-us: "\e91f";
@icon-estimated-delivery: "\e952";
@icon-earn-reward: "\e953";