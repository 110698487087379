@import '../framework/imports.less';

.form-group, .input-group {
    position: relative;
    box-sizing: border-box;
}

.form-group *, .input-group * {
    box-sizing: border-box;
}

.has-error .form-control, .has-error .form-control:focus {
    border-color: @brand-danger;
}

.has-error .form-control:focus {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 78, 0, 0.3);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 78, 0, 0.3);
}

.form-group .error-message, .input-group .error-message {
    background: @brand-danger;
    position: absolute;
    font-weight: normal;
    font-size: .9em;
    top: 100%;
    right: 0;
    left: auto;
    padding: .5em;
    line-height: 1.2em;
    text-align: right;
    z-index: 10;
    cursor: pointer;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.1);
    color: white;
    margin: .25em 0 0 0;
}

.input-group .error-message {
    left: 0;
    right: auto;
}

.error-message:before {
    position: absolute;
    top: -1.2em;
    right: 1em;
    content: "";
    border-top: .7em transparent solid;
    border-right: .7em transparent solid;
    border-left: .7em transparent solid;
    border-bottom: .7em @brand-danger solid;
}

.input-group .error-message:before {
    right: auto;
    left: 1em;
}

.radio .error-message, .checkbox .error-message {
    left: 0;
    right: auto;
    text-align: left;
    margin: 0;
    bottom: -2.5em;
}

.radio .error-message:before, .checkbox .error-message:before {
    left: 1em;
    right: auto;
}

.form-control.valid {
    position: relative;
}

//custom validation stuff
.row > div > .error-message {
    right: (@grid-gutter-width/2);
}

//needed for .net MVC validation
.error-message.field-validation-valid {
    display: none;
}