@import '../framework/imports.less';

.autoComplete_wrapper {
    position: relative;
}

.autoComplete_wrapper > ul {
    position: absolute;
    max-height: 226px;
    overflow-y: scroll;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: 0.5rem 0 0 0;
    padding: 0;
    z-index: 1;
    list-style: none;
    border-radius: 0.6rem;
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, 0.07);
    box-shadow: 0 3px 6px rgba(149, 157, 165, 0.15);
    outline: none;
    transition: opacity 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out;
    -webkit-transition: opacity 0.15s ease-in-out;
}

.autoComplete_wrapper > ul[hidden],
.autoComplete_wrapper > ul:empty {
    display: block;
    opacity: 0;
    transform: scale(0);
}

.autoComplete_wrapper > ul > li {
    margin: 0.3rem;
    padding: 0.3rem 0.5rem;
    text-align: left;
    font-size: 1rem;
    color: @color-black;
    border-radius: 0.35rem;
    background-color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
}

.autoComplete_wrapper > ul > li mark {
    background-color: transparent;
    font-weight: bold;
    padding: 0;
}

.autoComplete_wrapper > ul > li:hover {
    cursor: pointer;
    background-color: @color-gray-light;
}

.autoComplete_wrapper > ul > li[aria-selected="true"] {
    background-color: @color-gray-light;
}