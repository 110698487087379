// Z-Index Scale (private vars)
// --------------------------------------------------
@z-index-0: 0;
@z-index-1: 100;
@z-index-2: 200;
@z-index-3: 300;
@z-index-4: 400;
@z-index-5: 500;
@z-index-6: 600;
@z-index-7: 700;
@z-index-8: 800;
@z-index-9: 900;
@z-index-10: 1000;

// Z-Index Applications
// --------------------------------------------------

//Framework z-index values
//----------------------------------------------------

//mobile-navigation
@mobile-navigation-z-index: @z-index-0 + 1;
@mobile-list-z-index: @mobile-navigation-z-index + 1;
@mobile-department-z-index: @mobile-navigation-z-index + 2;
@mobile-page-z-index: @z-index-1;

//masthead

@masthead-z-index: @z-index-3;
@masthead-elements-z-index: @masthead-z-index + 10;
@utility-z-index: @masthead-z-index + 10;
@masthead-login-menu-z-index: @masthead-z-index + 11;
@masthead-skip-link-z-index: @masthead-z-index + 12;
@navigation-wrapper-before-z-index: @masthead-z-index - 1; //the white overlay
@navigation-wrapper-after-z-index: @masthead-z-index - 4; //the colored bar
@masthead-logo-z-index: @masthead-z-index;
@masthead-bg-z-index: @masthead-z-index - 1;
@masthead-department-z-index: @masthead-z-index;
@top-nav-item-z-index: @masthead-z-index+ 3;
@top-nav-link-z-index: @masthead-z-index + 5;
@subnavigation-z-index: @masthead-z-index - 3;
@subnavigation-hover-z-index: @masthead-z-index - 2;
@subnavigation-background-z-index: @masthead-z-index - 5;
@header-arrow-link-z-index: @masthead-z-index + 5;
@header-arrow-link-after-z-index: @masthead-z-index + 6;
@checkout-complete-hero-z-index: @masthead-z-index;

@footer-z-index: @z-index-2;
@footer-paragraph-z-index: @footer-z-index + 2;

// @navigation-z-index: @z-index-3;
// @subnavigation-z-index: @z-index-4;

@body-content-z-index: @z-index-0;
@blockquote-z-index: @z-index-1;

//headband
@headband-z-index: @z-index-6;
@headband-mobile-z-index: @z-index-6;

//Responsive Banners
@responsive-banner-hit-area-z-index: @z-index-1;

//Content Pages
@content-hero-z-index: @footer-z-index + 1;
@content-hero-overlay-z-index: @content-hero-z-index + 1;
@hero-banner-z-index: @content-hero-z-index;
@hero-banner-link-z-index: @hero-banner-z-index + 1;
@yml-products-z-index: @content-hero-z-index + 1;
@yml-nav-arrows-z-index: @content-hero-z-index + 11;
@mixin-loading-animation-after: @content-hero-z-index + 20;
@filters-active-z-index: @z-index-10;

@content-container-z-index: @z-index-2;
@blog-nav-z-index: @content-container-z-index + 1;
@blog-entry-date-z-index: @content-container-z-index + 3;
@blog-heder-category-z-index: @content-container-z-index + 2;
@blog-hero-banner-after-z-index: @content-container-z-index + 1;
@container-order-complete-z-index: @content-container-z-index + 1;

//Anchor navigation
@anchor-nav-z-index: @body-content-z-index + 20;
@top-anchor-z-index: @z-index-5;

@background-banner-z-index: @z-index-0;
@background-banner-shadow-z-index: @background-banner-z-index + 1;
@evotrip-section-z-index: @z-index-2 + 1;
@utility-nav-z-index: @content-container-z-index + 10;
@global-arrow-z-index: @content-container-z-index + 9;

//Compare
@compare-left-column-z-index: @z-index-1;
@sticky-product-thumbs-z-index: @compare-left-column-z-index - 1;
@compare-navigation-z-index: @compare-left-column-z-index + 1;

//Locations
@flagship-map-marker-z-index: 1000;

//Package Builder
@cf-hero-z-index: @content-hero-z-index;
@cf-base-rule-z-index: @cf-hero-z-index + 1;
@cf-hero-title-z-index: @cf-hero-z-index + 1;
@cf-content-z-index: @content-container-z-index;
@cf-sidebar-z-index: @cf-content-z-index + 1;
@cf-product-results-container-z-index: 10;
@cf-product-page-z-index: @cf-product-results-container-z-index - 1;
@product-scroll-products-z-index: 10;
@compare-specs-z-index: @product-scroll-products-z-index - 1;
@cf-summary-z-index: 1000;
@cf-summary-inner-z-index: 100;
@cf-summary-product-card-z-index: @cf-summary-inner-z-index - 1;

//Guides
@buying-guide-callout-z-index: @separator-overlay-z-index + 1;
@size-guide-overflow-gradient-z-index: @z-index-1;
@size-guide-overflow-indicator-z-index: @size-guide-overflow-gradient-z-index + 1;

//-----PDP---------------------------------------------
@pdp-page-z-index: @body-content-z-index;
@pdp-anchor-nav-z-index: @pdp-page-z-index + 10;
@pdp-content-z-index: @pdp-anchor-nav-z-index - 1;
@pdp-nav-z-index: @anchor-nav-z-index;
@pdp-recently-viewed-z-index: @pdp-nav-z-index - 1;
@pdp-breadcrumbs-z-index: @pdp-anchor-nav-z-index;
@pdp-anchor-nav-links-z-index: @pdp-breadcrumbs-z-index - 1;
@breadcrumbs-z-index: @body-content-z-index + 5;
@pdp-model-info-z-index: @pdp-page-z-index + 2;
@pdp-zoom-z-index: @pdp-page-z-index + 4;
@pdp-hero-utilities-index: @pdp-page-z-index + 5;
@pdp-zoom-close-z-index: @pdp-page-z-index + 6;
@pdp-hero-loading-bg-z-index: @pdp-page-z-index + 7;
@pdp-hero-loading-z-index: @pdp-page-z-index + 8;
@pdp-hero-alt-z-index: @pdp-page-z-index + 10;
@pdp-selection-error-z-index: @body-content-z-index + 2;
@pdp-selector-psuedo-z-index: @body-content-z-index + 1;

//-----Results---------------------------------------------
@results-sidebar-z-index: @z-index-7;
@results-sidebar-overlay-z-index: @results-sidebar-z-index - 1;
@results-sidebar-header-z-index: @results-sidebar-z-index + 1;
@results-header-z-index: @body-content-z-index + 2;
@results-header-image-z-index: @body-content-z-index + 1;
@results-content-z-index: @results-header-z-index + 1;

//-----Separators---------------------------------------------
@separator-shadow-z-index: @breadcrumbs-z-index;
@separator-overlay-z-index: @separator-shadow-z-index + 1;