@import 'imports.less';
@import 'footer-icons.less';

//formatting for the footer here
.footer-wrapper {
    background: @color-white;
    position: relative;
    z-index: @footer-z-index;
}

#footer-chalkboard {
    clear: both;
    padding-bottom: 100%; /* 800px/800px ~ height/width of background image */
    background-size: contain, 0 0;
    background-repeat: no-repeat, repeat;
    background-position: top center;
    font-size: 0;

    @media (min-width: @screen-xs) {
        padding-bottom: 13.75%; /* 165/1200 ~ height/width of background image */
        background-position: top left;
        position: relative;
        background-size: auto 100%, auto;
        max-height: 220px;
        width: 100%;
    }

    @media (min-width: @screen-lg-min) {
        height: 165px;
        width: 100%;
        background-size: 1200px 165px, auto;
        background-position: top center, 0 0;
        padding-bottom: 0;
    }
}

.footer-chalkboard-image.lazyloaded {
    background-image: url(//static.evo.com/content/www/framework/footer/we-are-evo-footer_800x800_2.jpg);

    @media (min-width: @screen-xs) {
        background-image: url(//static.evo.com/content/www/framework/footer/we-are-evo-chalkboard.jpg), url(//static.evo.com/content/www/framework/footer/chalkboard-bg.jpg);
    }
}

#footer-linkbar {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    background-color: @color-green-dark;
    font-size: 0;
    text-align: center;

    a {
        font-size: @font-size-small;
        font-weight: bold;
        display: inline-block;
        color: @color-white;
        text-transform: uppercase;
        padding-left: 0;
        padding-right: .4em;
        margin: .4em .3em;

        &:hover {
            text-decoration: underline;
        }

        @media (min-width: @screen-md-min) {
            font-size: @font-size-base;
            padding-right: 1.1em;
            margin: .5em 1em;

            &:after {
                right: -1em;
            }
        }
    }
}

.footer-element-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-bar-icon {
    color: white;

    svg {
        display: block;
        height: 20px;
    }
}

.no-touch #footer-linkbar a {
    .transition(@transition-base);
}

#footer-main {
    padding-top: 40px;
    #gradient .vertical(@color-gray-lighter; @color-white);
    position: relative;
}

.footer-main-background.lazyloaded {
    @media (min-width: @screen-sm-min) {
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ececec', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        background-image: url(//static.evo.com/content/www/framework/footer/corner-art_2015-06-06.jpg), url(//static.evo.com/content/www/framework/footer/main-gradient.png) !important;
        background-repeat: no-repeat !important;
        background-position: bottom right, top center !important;
        background-color: #ececec !important;
    }
}

.footer-column {
    .make-xs-column(12);
    .make-sm-column(6);
    .make-md-column(4);
    text-align: center;
}

.footer-support-column {
    text-align: left;
    float: left;
    width: 50%;
    font-size: 13px;

    @media (min-width: 1024px) {
        width: 25%;
    }

    h5 {
        font-size: 13px;
        color: @color-gray-darker;
        padding: 10px 10px 0 0;
        display: block;
        font-weight: normal;
        line-height: 1em;
        width: 100%;
        margin: 0;

        &:after {
            margin-left: 2px;
        }

        @media (min-width: @screen-sm-min) {
            font-size: @font-size-base;
        }
    }

    ul {
        .clear-list-formatting();
        margin: 10px 0 20px 0;
        display: block;
        float: left;
        width: 80%;

        &:before {
            content: '';
            height: 5px;
            width: 100%;
            background-color: @color-white;
            display: block;
            margin-bottom: 5px;
        }

        li {
            margin-top: .2em;
            margin-bottom: .2em;
        }

        a {
            color: @color-black;
            font-weight: normal;
            line-height: 1.2em;
            width: 100%;
            display: block;
            white-space: normal;
            margin-top: .7em;
            margin-bottom: .7em;

            &:hover {
                text-decoration: none;
                color: @color-green;
            }
        }
    }
}

#footer-facebook-like {
    margin-top: 20px;
}

#footer-social-icons {
    width: 80%;
    .clear-list-formatting();
    margin: 0 auto 30px auto;

    li {
        width: (80% / 5);
        display: inline-block;
    }
}

@media (min-width: 1024px) {
    #footer-social-col {
        width: 25%
    }

    #footer-column-primary {
        width: 50%;
    }
}

.footer-social-icon {
    float: left;
    width: 33px;
    height: 33px;
    font-size: 25px;
    color: @color-black;
    opacity: 1;
    .transition(@transition-base);

    &:hover {
        text-decoration: none;
        color: @color-black;
        opacity: .8;
    }
}

#footer-column-primary {
    display: flex;
    flex-wrap: wrap;
}

.footer-heading {
    font-size: 24px;
    margin-top: 0;
}

.footer-subhead {
    margin: 0;
    text-transform: none;
    font-size: 16px;
}

.footer-join-group {
    margin-top: 20px;
}

#footer-join-col {
    text-align: center;
    width: 100%;

    @media (min-width: 1280px) {
        width: 25%;
    }
}

.join-button {
    float: right;
    padding-right: (@padding-base-horizontal + 22px) !important;
    padding-left: 22px !important;
}

.join-field {
    width: 100%;
    height: 35px;
}

#footer-copyright {
    font-size: 12px;
    line-height: 1em;
    padding: 10px 20px;

    a {
        color: @color-green-darker;
    }

    span {
        vertical-align: middle;
    }

    @media (min-width: @screen-sm-min) {
        padding: 40px 20px 10px 20px;
    }
}

#footer-logo {
    width: 35px;
    height: 32px;
    line-height: 1em;
    display: block;
    float: none;
    margin: 10px auto;

    @media (min-width: @screen-sm-min) {
        vertical-align: middle;
        display: inline-block;
        margin: 0 10px 0 0;
    }
}

@media print {
    #footer-chalkboard,
    #footer-linkbar,
    .footer-column {
        display: none;
    }
}

#footer-google-trusted-store {
    position: absolute;
    right: 0;
    bottom: 0;
}

.featured-brands-container {
    margin-bottom: @margin-large;
}