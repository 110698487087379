@import '../framework/imports.less';
/* - Old ASP pages - */
.zr .headband {
    max-width: 1180px;
    margin: auto;
}

/* - Old Image Headband - */
.headband-full-img {
    display: block;
    margin: auto;
}

// Headband Framework
.headband {
    position: relative;
    display: block;
    color: @color-black;
    text-transform: uppercase;
    font-size: @font-size-small; // all font sizes of children are em based off this
    text-align: center;

    &:hover,
    &:active,
    &:focus {
        color: @color-black;
        text-decoration: none;
    }

    @media (max-width: @screen-xs-max) {
        .text-left,
        .text-right {
            text-align: center;
        }

        z-index: @z-index-1;
    }

    @media (min-width:@screen-sm) {
        line-height: 1em;
        text-align: inherit;
    }

    @media (min-width:@screen-lg) {
        font-size: 16px;
    }
}

// Mobile headband concept
@headband-transition: all .3s ease-out;

@media (max-width: @screen-xs-max) {
    .headband-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: @headband-mobile-z-index;
    }

    .icon-headband-toggle {
        position: absolute;
        top: -(30px + @margin-base);
        left: 5px;
        bottom: 0;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        font-size: 15px;
        color: @color-black;
        z-index: @z-index-2;
        text-align: center;
        opacity: .9;
        .box-shadow(0 1px 3px 0px fadeout(@color-black, 60%));
        .transition(top .15s linear .15s);

        &:before {
            content: "\e9ee";
            padding: 1px 2px 0 0;
            opacity: 1;
            line-height: 30px;
            display: block;
        }
    }

    .headband {
        display: none;
        width: 100%;
    }

    .headband-wrapper.is-open {
        .icon-headband-toggle {
            top: -15px;

            &:before {
                content: "\ed6a";
                padding: 1px 0 0 0;
            }
        }
    }
}

.reversed .icon-headband-toggle {
    color: #fff;
}
// HTML headband columns and layout
.reversed .headband {
    color: #fff;

    &:hover,
    &:active,
    &:focus {
        color: #fff;
    }

    .headband-separator span {
        background-color: rgba(255,255, 255, .33);
    }
}

.headband-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding: 5px 0;

    @media (min-width:@screen-sm) {
        padding: 9px 20px 9px 0;
    }

    @media (min-width:@screen-lg) {
        padding-right: 27px;
    }
}

.headband-row {
    @media (min-width:@screen-sm) {
        width: 100%;
        display: table;
    }
}

.headband-column {
    width: 100%;
    position: relative;
    padding: 0 @padding-large-horizontal; // clears close button and arrow
    @media (min-width: @screen-sm) {
        display: table-cell;
        vertical-align: middle;
        width: auto;
        padding: 0 @grid-gutter-width/2;
    }
}

.headband-column.has-countdown {
    @media (min-width: @screen-sm) {
        width: 22%;
    }

    @media (min-width: @screen-md) {
        width: 20%;
    }

    @media (min-width: @screen-lg) {
        width: 17%;
    }
}

.countdown-section {
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
}

.headband-separator {
    display: table-cell;
    vertical-align: middle;
    width: 30px;
    text-align: center;

    span {
        display: inline-block;
        width: 3px;
        height: 30px;
        .skew(-45deg; 0deg);
        background-color: rgba(0, 0, 0, .33);
    }
}

// Headband Typography
.headband-timer-title {
    @media (max-width: @screen-xs-max) {
        font-size: .85em;
    }

    @media (min-width: @screen-sm) {
        white-space: nowrap;
        display: block;
    }

    @media (min-width: @screen-lg) {
        font-size: .85em;
    }
}

.headband-date {
    font-weight: 700;
    font-style: italic;

    @media (min-width: @screen-sm) {
        text-align: center;
    }
}

.headband-message {
    font-size: 1.2em;
    line-height: 1.1em;
    vertical-align: middle;

    @media (min-width: @screen-sm) {
        font-size: 1.6em;
    }
}

.headband-message.font-smaller {
    @media (min-width: @screen-sm) {
        font-size: 1.4em;
    }

    @media (min-width: @screen-lg) {
        font-size: 1.2em;
    }
}

.headband-promo-code {
    @media (max-width: @screen-xs-max) {
        display: block;
    }

    @media (min-width: @screen-sm) {
        padding-left: 20px;
        vertical-align: middle;
    }
}

.headband-cta {
    display: none;

    @media (min-width: @screen-sm) {
        display: block;
        vertical-align: middle;
        white-space: nowrap;
        font-size: 1.25em;
    }

    @media (min-width: @screen-lg) {
        font-size: 1.5em;
    }
}

.font-emphasis {
    font-size: 1.5em;
    line-height: 1em;
    vertical-align: text-bottom;
}

.icon-clock {
    @media (min-width: @screen-sm) {
        font-size: 22px;
        float: left;
        padding-right: 5px;
    }

    @media (min-width: @screen-lg) {
        font-size: 25px;
        padding-top: 5px;
    }
}

// Headband Interface Elements
.headband-img {
    max-height: 50px;
    margin: auto;
    display: block;

    @media (min-width: @screen-md) {
        max-height: 60px;
    }
}

.headband-arrow {
    font-size: 1.5em;
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    .vertical-center();

    @media (min-width: @screen-sm) {
        font-size: 1.25em;
        right: 7px;
    }

    @media (min-width: @screen-lg) {
        font-size: 1.5em;
    }
}

.no-touch {
    .headband-arrow {
        .transition(@transition-base);
    }

    .headband:hover .headband-arrow,
    .headband:focus .headband-arrow,
    .headband:active .headband-arrow {
        right: 2px;
    }
}