﻿@import '../libs/icomoon.less';

@font-path: '//static.evo.com/content/www/fonts/mont/';

@font-face {
    font-family: 'Mont';
    src: url('//static.evo.com/content/www/fonts/mont/mont-regular.eot');
    src: url('//static.evo.com/content/www/fonts/mont/mont-regular.eot?#iefix') format('embedded-opentype'), url('//static.evo.com/content/www/fonts/mont/mont-regular.woff2') format('woff2'), url('//static.evo.com/content/www/fonts/mont/mont-regular.woff') format('woff'), url('//static.evo.com/content/www/fonts/mont/mont-regular.ttf') format('truetype'), url('//static.evo.com/content/www/fonts/mont/mont-regular.svg#youworkforthem') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.eot');
    src: url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.eot?#iefix') format('embedded-opentype'), url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.woff2') format('woff2'), url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.woff') format('woff'), url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.ttf') format('truetype'), url('//static.evo.com/content/www/fonts/mont/mont-regular-italic.svg#youworkforthem') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('//static.evo.com/content/www/fonts/mont/mont-semibold.eot');
    src: url('//static.evo.com/content/www/fonts/mont/mont-semibold.eot?#iefix') format('embedded-opentype'), url('//static.evo.com/content/www/fonts/mont/mont-semibold.woff2') format('woff2'), url('//static.evo.com/content/www/fonts/mont/mont-semibold.woff') format('woff'), url('//static.evo.com/content/www/fonts/mont/mont-semibold.ttf') format('truetype'), url('//static.evo.com/content/www/fonts/mont/mont-semibold.svg#youworkforthem') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('//static.evo.com/content/www/fonts/mont/mont-bold.eot');
    src: url('//static.evo.com/content/www/fonts/mont/mont-bold.eot?#iefix') format('embedded-opentype'), url('//static.evo.com/content/www/fonts/mont/mont-bold.woff2') format('woff2'), url('//static.evo.com/content/www/fonts/mont/mont-bold.woff') format('woff'), url('//static.evo.com/content/www/fonts/mont/mont-bold.ttf') format('truetype'), url('//static.evo.com/content/www/fonts/mont/mont-bold.svg#youworkforthem') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Amsi Pro Narrow';
    src: url('//static.evo.com/content/www/fonts/amsipronarrowblack/font.woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src: url('//static.evo.com/content/www/fonts/icomoon-20240424/evo-icomoon.eot?g60im7');
    src: url('//static.evo.com/content/www/fonts/icomoon-20240424/evo-icomoon.eot?g60im7#iefix') format('embedded-opentype'), url('//static.evo.com/content/www/fonts/icomoon-20240424/evo-icomoon.ttf?g60im7') format('truetype'), url('//static.evo.com/content/www/fonts/icomoon-20240424/evo-icomoon.woff?g60im7') format('woff'), url('//static.evo.com/content/www/fonts/icomoon-20240424/evo-icomoon.svg?g60im7#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-pen6 {
    &:before {
        content: @icon-pen6;
    }
}

.icon-bubble9 {
    &:before {
        content: @icon-bubble9;
    }
}

.icon-user4 {
    &:before {
        content: @icon-user4;
    }
}

.icon-link {
    &:before {
        content: @icon-link;
    }
}

.icon-filter {
    &:before {
        content: @icon-filter;
    }
}

.icon-share {
    &:before {
        content: @icon-share;
    }
}

.icon-plane {
    &:before {
        content: @icon-plane;
    }
}

.icon-membership {
    &:before {
        content: @icon-membership;
    }
}

.icon-help-center {
    &:before {
        content: @icon-help-center;
    }
}

.icon-pause2 {
    &:before {
        content: @icon-pause2;
    }
}

.icon-price-tag2 {
    &:before {
        content: @icon-price-tag2;
    }
}

.icon-phone2 {
    &:before {
        content: @icon-phone2;
    }
}

.icon-envelop3 {
    &:before {
        content: @icon-envelop3;
    }
}

.icon-user3 {
    &:before {
        content: @icon-user3;
    }
}

.icon-cross2 {
    &:before {
        content: @icon-cross2;
    }
}

.icon-notification2 {
    &:before {
        content: @icon-notification2;
    }
}

.icon-question4 {
    &:before {
        content: @icon-question4;
    }
}

.icon-play {
    &:before {
        content: @icon-play;
    }
}

.icon-facebook2 {
    &:before {
        content: @icon-facebook2;
    }
}

.icon-instagram {
    &:before {
        content: @icon-instagram;
    }
}

.icon-twitter {
    &:before {
        content: @icon-twitter;
    }
}

.icon-youtube3 {
    &:before {
        content: @icon-youtube3;
    }
}

.icon-pencil {
    &:before {
        content: @icon-pencil;
    }
}

.icon-location {
    &:before {
        content: @icon-location;
    }
}

.icon-link2 {
    &:before {
        content: @icon-link2;
    }
}

.icon-share2 {
    &:before {
        content: @icon-share2;
    }
}

.icon-location5 {
    &:before {
        content: @icon-location5;
    }
}

.icon-r0 {
    &:before {
        content: @icon-r0;
    }
}

.icon-r-5 {
    &:before {
        content: @icon-r-5;
    }
}

.icon-r1-5 {
    &:before {
        content: @icon-r1-5;
    }
}

.icon-r1 {
    &:before {
        content: @icon-r1;
    }
}

.icon-r2-5 {
    &:before {
        content: @icon-r2-5;
    }
}

.icon-r2 {
    &:before {
        content: @icon-r2;
    }
}

.icon-r3-5 {
    &:before {
        content: @icon-r3-5;
    }
}

.icon-r3 {
    &:before {
        content: @icon-r3;
    }
}

.icon-r4-5 {
    &:before {
        content: @icon-r4-5;
    }
}

.icon-r4 {
    &:before {
        content: @icon-r4;
    }
}

.icon-r5 {
    &:before {
        content: @icon-r5;
    }
}

.icon-evo-checkmark {
    &:before {
        content: @icon-evo-checkmark;
    }
}

.icon-clock {
    &:before {
        content: @icon-clock;
    }
}

.icon-clock2 {
    &:before {
        content: @icon-clock2;
    }
}

.icon-minus {
    &:before {
        content: @icon-minus;
    }
}

.icon-arrow-left {
    &:before {
        content: @icon-arrow-left;
    }
}

.icon-arrow-left-medium {
    &:before {
        content: @icon-arrow-left-medium;
    }
}

.icon-arrow-left-large {
    &:before {
        content: @icon-arrow-left-large;
    }
}

.icon-arrow-right {
    &:before {
        content: @icon-arrow-right;
    }
}

.icon-arrow-right-medium {
    &:before {
        content: @icon-arrow-right-medium;
    }
}

.icon-arrow-right-large {
    &:before {
        content: @icon-arrow-right-large;
    }
}

.icon-arrow-up-small {
    &:before {
        content: @icon-arrow-up-small;
    }
}

.icon-arrow-up-medium {
    &:before {
        content: @icon-arrow-up-medium;
    }
}

.icon-arrow-up-large {
    &:before {
        content: @icon-arrow-up-large;
    }
}

.icon-arrow-down {
    &:before {
        content: @icon-arrow-down;
    }
}

.icon-arrow-down-medium {
    &:before {
        content: @icon-arrow-down-medium;
    }
}

.icon-arrow-down-large {
    &:before {
        content: @icon-arrow-down-large;
    }
}

.icon-search {
    &:before {
        content: @icon-search;
    }
}

.icon-zoom-in {
    &:before {
        content: @icon-zoom-in;
    }
}

.icon-zoom-out {
    &:before {
        content: @icon-zoom-out;
    }
}

.icon-google-plus2 {
    &:before {
        content: @icon-google-plus2;
    }
}

.icon-facebook {
    &:before {
        content: @icon-facebook;
    }
}

.icon-google-plus {
    &:before {
        content: @icon-google-plus;
    }
}

.icon-pinterest {
    &:before {
        content: @icon-pinterest;
    }
}

.icon-tiktok {
    &:before {
        content: @icon-tiktok;
    }
}

.icon-linkedin {
    &:before {
        content: @icon-linkedin2;
    }
}

.icon-cart {
    &:before {
        content: @icon-cart;
    }
}

.icon-evo-text {
    &:before {
        content: @icon-evo-text;
    }
}

.icon-ruler {
    &:before {
        content: @icon-ruler;
    }
}

.icon-plus {
    &:before {
        content: @icon-plus;
    }
}

.icon-plus {
    &:before {
        content: @icon-plus;
    }
}

.icon-evo_holiday_gifting_shop_icon {
    &:before {
        content: "\e901";
        color: #d35e49;
        padding-right: 7px;
    }
}

.icon-free-shipping {
    &:before {
        content: @icon-free-shipping;
    }
}

.icon-returns {
    &:before {
        content: @icon-returns;
    }
}

.icon-lowest-price {
    &:before {
        content: @icon-lowest-price;
    }
}

.icon-members-earn {
    &:before {
        content: @icon-members-earn;
    }
}

.icon-puzzle {
    &:before {
        content: @icon-puzzle;
    }
}

.icon-location-pin {
    &:before {
        content: @icon-location-pin;
    }
}