@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/grid.less';

//flexible page
.page-fluid-925 {
    .container {
        min-width: @evo-container-min;
        .box-sizing(border-box);
    }

    .full-width {
        min-width: @evo-container-min;
    }
}

.container {
    //@media (min-width: @screen-xs-min) {
    padding-left: @grid-gutter-width/2;
    padding-right: @grid-gutter-width/2;
    // }
    @media (min-width: @screen-lg-min) {
        width: auto;
        max-width: @screen-lg-min;
        padding-left: @grid-gutter-width/2;
        padding-right: @grid-gutter-width/2;
    }

    &.half,
    & .half {
        max-width: ((@screen-lg-min / 2) - @grid-gutter-width);
        margin-left: auto;
        margin-right: auto;
    }
}

.container-large {
    width: 100%;
    padding-left: @grid-gutter-width;
    padding-right: @grid-gutter-width;

    @media (min-width: @screen-xs) {
        padding-left: @grid-gutter-width;
        padding-right: @grid-gutter-width;
        .clearfix();
    }

    @media (min-width: @navigation-breakpoint-large) {
        max-width: @masthead-max-width;
        margin-left: auto;
        margin-right: auto;
    }
}