﻿@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/tables.less';
// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    width: 100%;
    overflow-x: auto;
    min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
    @media screen and (max-width: @screen-xs-max) {
        width: 100%;
        margin-bottom: (@line-height-computed * 0.75);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        // Tighten up spacing
        > .table {
            margin-bottom: 0;
            // Ensure the content doesn't wrap
            > thead,
            > tbody,
            > tfoot {
                > tr {
                    > th,
                    > td {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}