﻿@import '../framework/imports.less';

/* Five9 CSS styling for the frame and the maximize and minimize button are not configurable in Five9 and need to be 
 managed here instead */

.five9-frame {
    right: 2% !important;
}

div#five9-maximize-button.five9-chat-button {
    float: right !important;
    background: linear-gradient(#40ae32, #0f8800) !important;
}

div#five9-minimize-button.five9-chat-button {
    background: linear-gradient(#40ae32, #0f8800) !important;
}