﻿@import 'imports.less';

html, body {
    width: 100%;
}

body {
    overflow-x: hidden;
    position: relative;
}

.page {
    background: #fff;
    z-index: @mobile-page-z-index;

    @media (max-width: (@navigation-breakpoint - 1)) {
        overflow-x: hidden;
        position: relative;
    }
}

.wrapper {
    position: relative;
    z-index: @content-hero-z-index;
}

.filters-active {
    .wrapper {
        z-index: @filters-active-z-index;
    }
}

img {
    max-width: 100%;
}

.fixed {
    position: fixed !important;
}