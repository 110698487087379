@import 'mixins.less';
@import 'variables.less';
@import '../../libs/bootstrap/alerts.less';

.alert {
    padding: 5px 10px;
    position: relative;

    strong {
        text-transform: uppercase;
    }
}

.alert-inline {
    display: inline-block;
    position: relative;

    strong {
        text-transform: uppercase;
    }
}

.alert-success {
    strong {
        color: lighten(@color-green, 40%);
    }
}

.alert-danger, .alert-warning {
    strong {
        color: lighten(@color-red, 40%);
    }
}

.alert-warning {
    background-color: @gray-lighter;
    border: 2px solid @color-red;
    color: @color-black;
    font-weight: normal;
}

.alert-warning a {
    color: @color-red;
}

.alert-icon {
    position: relative;
    padding-left: 25px;

    &:before {
        content: "\ed4f";
        font-family: 'icomoon';
        color: @color-red;
        position: absolute;
        top: 5px;
        left: 5px;
    }
}

.alert-inline.alert-icon:before {
    top: 0;
}